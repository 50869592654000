<template>
  <v-card class="rounded-lg no-scroll dialog-card pa-0" flat>
    <div class="dialog-card">
      <v-row
        class="ma-0 mb-6 movements-header"
        justify="space-between"
        align="center"
      >
        <div>
          <span class="member-name mr-4">{{ truncatedName }}</span>
          <span class="date-range">{{ formattedDateRange }}</span>
        </div>
        <div class="d-flex align-center">
          <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mr-2 button"
                outlined
                large
                @click="dateMenu = !dateMenu"
              >
                <v-icon size="20" class="mr-1">mdi-calendar-outline</v-icon>
                <span class="button-text">Rango de Fechas</span>
              </v-btn>
            </template>
            <v-date-picker
              v-model="dateRange"
              range
              locale="es"
              no-title
            ></v-date-picker>
          </v-menu>

          <v-menu
            v-model="filterMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-2 button"
                outlined
                large
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="20" class="mr-1">mdi-filter-outline</v-icon>
                <span class="button-text">Filtrar</span>
              </v-btn>
            </template>

            <v-card color="white" class="pa-2" width="400">
              <v-row class="ma-0 my-2" justify="space-between" align="center">
                <v-btn class="mr-2" outlined small @click="resetFilters()">
                  <span class="filter-button">Borrar</span>
                </v-btn>

                <span class="mr-2 font-weight-bold">Filtros</span>

                <v-btn class="mr-2" small color="#1b273e" @click="getMemberMovements()">
                  <span class="filter-button white--text">Listo</span>
                </v-btn>
              </v-row>

              <hr />

              <v-row class="py-2">
                <v-col class="py-1 filter-item" cols="12" v-for="category in categories" :key="category">
                  <v-checkbox
                    hide-details
                    class="shrink"
                    dense
                    multiple
                    color="#1b273e"
                    v-model="filters"
                    :label="category"
                    :value="category"
                  >
                    <template v-slot:label>
                      <span class="black--text wc-caption-2">{{ category }}</span>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-card>
          </v-menu>

          <v-dialog
            v-model="newMovementDialog"
            class="dialog-card white"
            width="30%"
            @click:outside="resetForm()"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-2 button"
                large
                color="#1b273e"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="white" size="20" class="mr-1">mdi-plus</v-icon>
                <span class="button-text white--text">Nuevo Movimiento</span>
              </v-btn>
            </template>

            <v-card color="white" class="rounded-sm no-scroll" flat>
              <div class="dialog-card pa-8 d-flex flex-column">
                <span class="form-label">Titulo</span>
                <v-text-field
                  outlined
                  dense
                  class="mt-2"
                  v-model="newMovementForm.title"
                ></v-text-field>

                <span class="form-label">Descripción</span>
                <v-textarea
                  v-model="newMovementForm.description"
                  outlined
                  dense
                  height="112px"
                  class="mt-2"
                ></v-textarea>

                <span class="form-label">Fecha</span>
                <v-menu
                  v-model="newMovementDateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="button mt-2"
                      outlined
                      large
                    >
                      <v-row align="center" class="px-2">
                        <v-icon color="grey lighten-1" size="20" class="mr-2"
                          >mdi-calendar-outline</v-icon
                        >
                        <span class="button-text grey--text text--lighten-1">
                          {{
                            newMovementForm.date != ""
                              ? newMovementForm.date
                              : "Selecciona una fecha"
                          }}
                        </span>
                      </v-row>
                    </v-btn>
                  </template>
                  <v-date-picker
                    v-model="newMovementForm.date"
                    locale="es"
                    no-title
                  ></v-date-picker>
                </v-menu>

                <span class="form-label mt-4">Color</span>

                <v-row
                  class="ma-0 color-outline py-2 px-4 mt-2"
                  justify="center"
                  align="center"
                >
                  <div
                    class="color-activator"
                    :style="`background-color: ${newMovementForm.color}`"
                  ></div>
                </v-row>

                <span class="form-label mt-4">Categoria</span>

                <v-select
                  v-model="newMovementForm.category"
                  :items="categories"
                  outlined
                  dense
                  class="mt-2"
                  @change="setCategoryColor()"
                ></v-select>

                <v-btn
                  class="mr-2 button white--text mb-4"
                  large
                  color="#1b273e"
                  @click="createMovement()"
                >
                  <span class="button-text white--text">Crear Movimiento</span>
                </v-btn>

                <hr />
              </div>
            </v-card>
          </v-dialog>
        </div>
      </v-row>

      <v-expansion-panels flat>
        <v-expansion-panel
          v-for="(day, i) in groupedMovements"
          :key="i"
          class="mb-2 pa-0 day-card rounded-lg"
        >
          <v-expansion-panel-header color="#f8fafc" class="rounded-lg">
            <v-row class="ma-0" align="center" justify="space-between">
              <div class="day-card-title">
                {{ day.day + " de " + monthName(day.month) + ", " + day.year }}
              </div>
              <div class="d-flex align-center">
                <div
                  v-for="(color, index) in day.colors"
                  :key="index"
                  class="color-bar mr-1"
                  :style="`background-color: ${color}`"
                ></div>
                <v-icon class="mx-1" color="black" size="18"
                  >mdi-clipboard-text</v-icon
                >
                <span class="movements-counter mr-4">
                  {{ day.movements.length }}
                </span>
              </div>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content color="#f8fafc" class="rounded-lg">
            <v-row
              v-for="movement in day.movements"
              :key="movement._id"
              class="ma-0 pa-2 py-3 mt-1 movement-card"
            >
              <div
                class="big-color-bar mr-4"
                :style="`background-color: ${movement.color}`"
              ></div>
              <div class="d-flex flex-column">
                <span class="movement-title">{{ movement.title }}</span>
                <span class="movement-description">{{
                  movement.description
                }}</span>
              </div>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  props: {
    member: {
      type: Object,
      required: true,
    },
    selectedMember: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      startDate: new Date(),
      endDate: new Date(),
      dateMenu: false,
      filterMenu: false,
      newMovementDateMenu: false,
      newMovementDialog: false,
      colorMenu: false,
      settedStartDate: false,
      newMovementForm: {
        title: "",
        description: "",
        date: "",
        color: "#000",
        category: "",
      },
      categories: [
        "Ingreso",
        "Baja",
        "Cambio de categoría",
        "Reingreso",
        "Cambio de grupo",
        "Fallecimiento",
        "Año de Gracia",
        "Evento",
        "Consejo Directivo",
        "Comité Técnico",
      ],
      categoriesColors: {
        Ingreso: "#4CAF50",
        Baja: "#FF5252",
        "Cambio de categoría": "#FFA726",
        Reingreso: "#66BB6A",
        "Cambio de grupo": "#42A5F5",
        Fallecimiento: "#757575",
        "Año de Gracia": "#FF69B4",
        Evento: "#26C6DA",
        "Consejo Directivo": "#2C3E50",
        "Comité Técnico": "#8E44AD",
      },
      swatches: [],
      movements: [],
      filters: [],
    };
  },

  methods: {
    ...mapMutations("loader", ["loading", "loaded"]),
    setCategoryColor() {
      this.newMovementForm.color =
        this.categoriesColors[this.newMovementForm.category];
    },
    formattedDate(date) {
      return date.toLocaleDateString("es-ES", {
        day: "2-digit",
        month: "short",
      });
    },
    resetForm() {
      this.newMovementForm = {
        title: "",
        description: "",
        date: "",
        color: "#000",
        category: "",
      };
    },

    resetFilters() {
      this.filters.splice(0, this.filters.length);
      this.filterMenu = false;
      this.getMemberMovements();
    },

    async getMemberMovements() {
      this.loading();
      try {
        const response = await this.axios({
          method: "POST",
          url: `/movements/${this.member.id}`,
          data: {
            startDate: this.startDate,
            endDate: this.endDate,
            filters: this.filters,
          },
        });

        this.movements.splice(0, this.movements.length);

        this.movements = JSON.parse(JSON.stringify(response.data.movements));
      } catch (error) {
        console.log(error);
      } finally {
        this.loaded();
        this.filterMenu = false;
        this.dateMenu = false;
      }
    },

    async createMovement() {
      this.loading();
      try {
        const response = await this.axios({
          method: "POST",
          url: `/movements/create/${this.member.id}`,
          data: this.newMovementForm,
        });

        if (response.data) {
          this.getMemberMovements();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.resetForm();
        this.newMovementDialog = false;
        this.loaded();
      }
    },

    monthName(month) {
      let months = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ];

      return months[month];
    },
  },

  computed: {
    formattedDateRange() {
      return `${this.formattedDate(this.startDate)} - ${this.formattedDate(
        this.endDate
      )}`;
    },

    truncatedName() {
      let name = this.member.name + " " + this.member.firstLastname;
      if (name.length > 25) {
        return name.substring(0, 25) + "...";
      }
      return name;
    },

    dateRange: {
      get() {
        // Return an array with the start and end date
        // dates must be in the format 'YYYY-MM-DD'

        let startDate =
          this.startDate.getFullYear() +
          "-" +
          (this.startDate.getMonth() + 1 < 10 ? "0" : "") +
          (this.startDate.getMonth() + 1) +
          "-" +
          (this.startDate.getDate() < 10 ? "0" : "") +
          this.startDate.getDate();

        let endDate =
          this.endDate.getFullYear() +
          "-" +
          (this.endDate.getMonth() + 1 < 10 ? "0" : "") +
          (this.endDate.getMonth() + 1) +
          "-" +
          (this.endDate.getDate() < 10 ? "0" : "") +
          this.endDate.getDate();

        return [startDate, endDate];
      },
      set(value) {
        if (this.settedStartDate) {
          let endDateArray = value[0].split("-");
          this.endDate = new Date(
            endDateArray[0],
            endDateArray[1] - 1,
            endDateArray[2]
          );

          this.getMemberMovements();
        } else {
          let startDateArray = value[0].split("-");
          this.startDate = new Date(
            startDateArray[0],
            startDateArray[1] - 1,
            startDateArray[2]
          );
        }

        this.settedStartDate = !this.settedStartDate;
      },
    },

    movementsColors() {
      let colors = [];

      for (let movement of this.movements) {
        if (!colors.includes(movement.color)) {
          colors.push(movement.color);
        }
      }

      //group in arrays of 3
      colors = colors.reduce((acc, curr, i) => {
        if (i % 3 === 0) acc.push([]);
        acc[acc.length - 1].push(curr);
        return acc;
      }, []);

      return colors;
    },

    groupedMovements() {
      let groupedMovements = [];

      if (this.movements.length == 0) return groupedMovements;

      for (let movement of this.movements) {
        let date = new Date(movement.date);
        let day = date.getDate();
        let month = date.getMonth();
        let year = date.getFullYear();

        let dayMovements = groupedMovements.find(
          (group) =>
            group.day == day && group.month == month && group.year == year
        );

        if (dayMovements) {
          dayMovements.movements.push(movement);

          if (!dayMovements.colors.includes(movement.color)) {
            dayMovements.colors.push(movement.color);
          }
        } else {
          groupedMovements.push({
            day,
            month,
            year,
            date,
            movements: [movement],
            colors: [movement.color],
          });
        }
      }

      //sort by date
      groupedMovements.sort((a, b) => b.date - a.date);

      return groupedMovements;
    },
  },

  watch: {
    dateMenu(value) {
      if (!value) {
        this.settedStartDate = false;
      }
    },

    selectedMember() {
      if (this.selectedMember == this.member.id) {
        this.startDate = new Date();
        this.endDate = new Date();
        this.startDate.setDate(this.startDate.getDate() - 30);

        this.getMemberMovements();
      }
    },
  },

  created() {
    this.startDate.setDate(this.startDate.getDate() - 30);
    this.getMemberMovements();
  },
};
</script>

<style>
.dialog-card {
  height: 80vh;
  padding: 40px 64px;
  font-family: Montserrat, sans-serif;
}

.v-dialog {
  background-color: white;
  overflow-y: auto; /* Permitir desplazamiento vertical */
  overflow-x: hidden; /* Ocultar desplazamiento horizontal */
  -ms-overflow-style: none; /* IE y Edge */
  scrollbar-width: none; /* Firefox */
}

.v-dialog::-webkit-scrollbar {
  display: none; /* Ocultar scroll */
}

.member-name {
  font-size: 24px;
  font-weight: 700;
  color: #000;
  text-transform: capitalize;
}

.date-range {
  font-size: 16px;
  font-weight: 700;
  color: #a5adb7;
}

.button-text {
  font-size: 12px;
  font-weight: 600;
  color: #000;
  letter-spacing: normal;
  text-transform: capitalize;
}

.button {
  border-color: #c1c7cf;
}

.day-card {
  border-radius: 8px;
  border: 2px solid #ced7e3;
  background-color: #f8fafc;
}

.day-card-title {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.color-bar {
  width: 6px;
  height: 16px;
  border-radius: 4px;
}

.movements-counter {
  font-size: 12px;
  font-weight: 600;
  color: #000;
}

.expand-button {
  border: 1px solid #ced7e3 !important;
  border-color: #b6bec9 !important;
  border-radius: 4px;
  background-color: white;
}

.movement-card {
  border-radius: 8px;
  border: 2px solid #ced7e3;
  background-color: white;
}

.v-expansion-panel-content__wrap {
  padding: 8px 4px 4px 4px;
}

.big-color-bar {
  width: 6px;
  height: auto;
  border-radius: 4px;
}

.movement-title {
  font-size: 14px;
  font-weight: 700;
  color: #000;
}

.movement-description {
  font-size: 11px;
  font-weight: 500;
  color: #858484;
}

.movements-header {
  position: sticky !important;
}

.form-label {
  font-size: 16px;
  font-weight: 700;
  color: #000;
}

.color-outline {
  border: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 4px;
}

.color-activator {
  width: 100%;
  height: 20px;
  border-radius: 4px;
  background-color: rgb(19, 19, 117);
}

.filter-button {
  text-transform: capitalize !important;
  letter-spacing: normal !important;
}

.filter-item {
  border-bottom: 1px solid #e0e0e0;
}
</style>