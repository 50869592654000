<template>
  <v-container fluid>
    <h5 class="wc-h5 mb-4">
      Modificar estatus del miembro
    </h5>
    <span>En este apartado podrás cambiar el status del miembro.</span>
    <!-- <v-chip :color="homologateStatusWithColor(memberStatus).color
      " class="rounded-lg font-weight-bold">
      {{
        homologateStatusWithColor(memberStatus)
          .status
      }}
    </v-chip> -->
    <v-row class="mt-2 mb-8">
      <v-col md="6" sm="12" class="pb-0">
        <v-select filled dense rounded label="Nuevo estatus del miembro" :items="userStatusList"
          v-model="selectedStatus">
        </v-select>
      </v-col>
      <v-col cols="12">
        <v-form v-model="membershipTypeSelected">
          <v-row>
            <v-col sm="6" md="6" v-if="selectedStatus == 'ACTIVE'">
              <v-select v-model="selectedAffiliation" :items="availableAffiliationTypes" item-text="name"
                item-value="_id" label="Tipo de membresía" filled dense rounded>
              </v-select>
            </v-col>
            <v-col sm="6" md="4" v-if="selectedStatus == 'ACTIVE'">
              <v-text-field v-model="affiliationPrice" type="number" dense label="Monto" value="0" filled rounded
                @keyup="validateMembershipPrice()" />
            </v-col>
            <v-col sm="6" md="6" class="ma-0 pa-0">
              <v-btn elevation="0" class="white--text text-bold rounded-pill" :color="color.primaryVariant"
                @click="$_handleUpdateUserStatus()">
                <v-icon class="mr-2">
                  mdi-content-save
                </v-icon>
                Actualizar Estatus
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>

    <h5 class="wc-h5 mb-4">
      Gestionar Contraseñas
    </h5>
    <span> En este apartado podrás restablecer la contraseña del miembro </span>
    <div class="mt-8">
      <v-radio-group v-model="passwordRecoverOption" class="recover-password-group" hide-details>
        <v-radio label="Cambiar manualmente la contraseña del usuario" value="changePasswordManual" />
        <v-radio label="Enviar enlace por correo para restablecer contraseña" value="sendRecoveryEmail" />
      </v-radio-group>
    </div>
    <div md="3">
      <v-row no-gutters>
        <v-col cols="12" v-if="passwordRecoverOption == 'changePasswordManual'">
          <v-col md="12">
            <span class="pa-0 ma-0">
              Establecer nueva contraseña para el usuario.
            </span>
          </v-col>
          <v-col sm="12" md="6">
            <v-form v-model="resetPasswordForm">
              <v-text-field label="Nueva contraseña del miembro" filled dense rounded v-model="newPassword"
                :type="showPass ? 'text' : 'password'" :rules="[...passwordRules]"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPass = !showPass">
              </v-text-field>
            </v-form>
          </v-col>
        </v-col>
        <v-col sm="12" md="12">
          <v-btn elevation="0" :disabled="passwordRecoverOption != 'sendRecoveryEmail'
            ? !resetPasswordForm
            : false
            " :color="color.primaryVariant" class="rounded-pill text-uppercase text-bold white--text"
            @click="() => (dialogSwitch = true)">
            <!-- <v-icon>
                      mdi-send
                    </v-icon> -->
            {{
              passwordRecoverOption == "changePasswordManual"
                ? "Restablecer contraseña"
                : "Enviar correo"
            }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <personalized-dialog :dialog="dialogSwitch" @save="$_handleResetPassword()" @discard="() => (dialogSwitch = false)">
      <template #alertName>
        <h5>
          {{ confirmationMessages[passwordRecoverOption].title }}
        </h5>
      </template>
      <template #alertContent>
        <span style="font-size: 16px; text-align: center">
          {{ confirmationMessages[passwordRecoverOption].value }}
        </span>
      </template>
    </personalized-dialog>
  </v-container>
</template>
<script>
import PersonalizedDialog from "./PersonalizedDialog.vue";
import { homologateStatusWithColor } from "@/common/hook/useHelper.js";
import { colors } from "@/constants/colors.js";
import { mapMutations, mapState } from "vuex/dist/vuex.common.js";
import { validations } from "@/share/validations";
export default {
  props: {
    memberId: {
      type: String,
      default: "",
    },
    memberEmail: {
      type: String,
      default: "",
    },
    memberStatus: {
      type: String,
      default: ""
    },
    availableAffiliationTypes: {
      type: Array,
      default: (() => [])
    }
  },
  components: {
    PersonalizedDialog,
  },
  data() {
    return {
      passwordRecoverOption: "changePasswordManual",
      newPassword: "",
      resetPasswordForm: true,
      passwordRules: validations.newPasswordRule(),
      showPass: true,
      color: colors,
      dialogSwitch: false,
      membershipTypeSelected: null,
      selectedStatus: "",
      selectedAffiliation: null,
      affiliationPrice: 0,
      userStatusList: [
        {
          text: 'Activo',
          value: 'ACTIVE'
        },
        {
          text: 'Inactivo',
          value: 'INACTIVO'
        },
        {
          text: 'Expirado',
          value: 'EXPIRED'
        },
      ],
      confirmationMessages: {
        changePasswordManual: {
          value:
            "Estás a punto de confirmar el restablecimiento de la contraseña. ¿Deseas continuar?",
          title: "Confirmar Restablecimiento de Contraseña",
          url: "/authentication/reset-member-password",
          response:
            "La contraseña del usuario ha sido restablecida correctamente",
        },
        sendRecoveryEmail: {
          value:
            "Estás a punto de confirmar el envío del correo para restablecer la contraseña. ¿Deseas continuar?",
          title: "Confirmar Envío de Correo",
          url: "/authentication/password-recover",
          response:
            "El enlace ha sido enviado al correo del usuario correctamente.",
        },
      },
    };
  },
  methods: {
    ...mapMutations("notification", ["show"]),
    ...mapMutations("loader", ["loading", "loaded"]),
    homologateStatusWithColor,

    disableOptions(statusArray = []) {
      if (statusArray.length <= 0) return [...this.userStatusList];

      let disabledStatusArray = [...this.userStatusList].reduce((accum, currentStatus) => {
        if (!([...statusArray].includes(currentStatus.value))) return [...accum, currentStatus];
        return [...accum, { ...currentStatus, disabled: true }]
      }, [])

      return disabledStatusArray
    },

    async $_handleResetPassword() {
      this.loading();
      try {
        const passwordDirective =
          this.confirmationMessages[this.passwordRecoverOption];
        const payload = {
          memberId: this.memberId,
          updatedPassowrd: this.newPassword,
          email: this.memberEmail,
        };

        const response = await this.axios({
          url: passwordDirective.url,
          method: "POST",
          data: payload,
        });
        if (response.status == 200) {
          this.show({
            text: passwordDirective.response,
            color: "success",
          });
        }
      } catch (error) {
        console.log(error);
        this.show({
          text: "Hubo un error al restablecer la contraseña del usuario, por favor intenta nuevamente",
          color: "error",
        });
      } finally {
        this.newPassword = "";
        this.loaded();
      }
    },

    async $_handleUpdateUserStatus() {
      try {
        let response = await this.axios({
          url: '/users/update-status-manually',
          method: 'POST',
          data: {
            memberId: this.memberId,
            status: this.selectedStatus,
            membershipId: this.selectedAffiliation,
            total: this.affiliationPrice
          }
        });
        if (response.status == 200) {
          this.show({
            text: "El estatus del miembro ha sido actualizado exitosamente",
            color: "success",
        });
        }
      } catch (error) {
        this.show({
          text: "Hubo un error al momento de actualizar el estatus del miembro",
          color: "error",
        });
      } finally {
        this.$emit("update");
        this.resetData();
      }
    },

    validateMembershipPrice() {
      this.affiliationPrice = parseInt(
        this.affiliationPrice
      );
      if (this.affiliationPrice < 0) {
        this.affiliationPrice = 0;
      }
    },

    getAffiliationPrice(currentAffiliation = '') {
      let affiliations = [...this.availableAffiliationTypes];
      if (affiliations.length <= 0) return 0;
      let newPrice = affiliations.find(element => element._id === currentAffiliation)?.price;
      return newPrice >= 0 ? newPrice : 0;
    },

    setupStatusBlock() {
      let statusToBlock = [];
      statusToBlock.push(this.memberStatus)
      switch (this.memberStatus) {
        case 'PROSPECTO':
          statusToBlock.push(...['INACTIVO', 'EXPIRED']);
          break;
        case 'ACTIVE':
          statusToBlock.push('EXPIRED');
          break;
      }
      console.log(this.memberStatus)
      console.log(statusToBlock)
      this.userStatusList = this.disableOptions(statusToBlock);
    },

    resetData() {
      this.selectedStatus = '';
      this.affiliationPrice = 0;
      this.selectedAffiliation = null;
      this.userStatusList = [
        {
          text: 'Activo',
          value: 'ACTIVE'
        },
        {
          text: 'Inactivo',
          value: 'INACTIVO'
        },
        {
          text: 'Expirado',
          value: 'EXPIRED'
        },
      ]
    },
  },

  watch: {
    selectedAffiliation(prev, next) {
      this.affiliationPrice = this.getAffiliationPrice(next);
    },
    memberStatus(prev, next) {
      this.setupStatusBlock()
    }
  },

  computed: {
    ...mapState('user', ['user']),
  },

  mounted() {
    this.setupStatusBlock();
  },
};
</script>