<template>
  <v-container>
    <!-- Search Bar -->
    <v-row class="mx-5">
      <v-col cols="12">
        <div class="d-flex flex-row my-4">
          <v-btn
            fab
            small
            elevation="0"
            class="rounded-pill rounded-r-0"
            :color="color"
            @click="searchMembers"
          >
            <v-icon size="20">mdi-magnify</v-icon></v-btn
          >
          <input
            type="text"
            placeholder="Buscar en directorio"
            class="rounded-pill rounded-l-0"
            v-model="search"
            @keyup.enter="searchMembers"
          />
        </div>
      </v-col>
    </v-row>

    <v-row class="mx-5">
      <v-col cols="12" sm="6" md="3">
        <v-select
          :items="orderedBranches"
          item-text="company"
          item-value="_id"
          label="Sección"
          solo
          clearable
          v-model="branch"
          @change="searchMembers"
        >
        </v-select>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-select
          :items="roles"
          label="Tipo de Membresia"
          clearable
          solo
          v-model="role"
          @change="searchMembers"
        >
        </v-select>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <v-col cols="10" md="6">
        <v-pagination
          :length="totalPages"
          v-model="membersPage"
          :total-visible="7"
          @input="getMembersSearch"
          class="mb-4"
        ></v-pagination>
      </v-col>
      <v-col lg="3" md="4" sm="6" cols="10" class="absolute-right">
        <v-row align="center" justify="center" justify-md="end" class="mx-0">
          <span>Mostrar</span>
          <v-col cols="6" sm="4" class="ma-0">
            <v-select
              :items="items"
              v-model="itemsToGet"
              @change="getMembersSearch"
            ></v-select>
          </v-col>
          <span v-if="$vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.md"
            >por página</span
          >
        </v-row>
      </v-col>
    </v-row>

    <!-- Directory Card List -->
    <v-row class="px-5 pb-5" id="membersScroll" justify="center">
      <v-col
        cols="12"
        sm="6"
        md="4"
        v-for="member in micrositeMembers"
        :key="member._id"
        class="pr-0"
      >
        <v-card
          height="100%"
          width="100%"
          style="border-radius: 15px"
          elevation="0"
          :to="{
            path: `/member/credential/${member._id}`,
            query: { micrositeId: micrositeId },
          }"
        >
          <v-img class="rounded-b-0" style="background: #d0e1db" height="70%">
            <v-row class="d-flex">
              <v-col cols="4" class="pr-0">
                <v-list-item-avatar size="90" color="white" class="mx-3">
                  <v-img
                    :src="profilePicture"
                    v-if="
                      member.profilePicture == 'NOT_ASSIGNED' ||
                      (member.profilePicture == null &&
                        (!member.directoryData || !member.directoryData.logo))
                    "
                  />
                  <v-img
                    :src="member.directoryData.logo || member.profilePicture"
                    v-else
                  />
                </v-list-item-avatar>
              </v-col>

              <v-col cols="8" class="pl-0 my-2">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title
                      style="font-size: 14px; font-weight: 900; color: #222b4c"
                      class="text-wrap mb-2"
                      >{{
                        member.headOffice === '66fb2d896646ca001cb93b3f' ? 
                        member.directoryData.name || member.name + " " + member.firstLastname :
                        member.directoryData.company || member.company || "N/D"
                      }}</v-list-item-title
                    >
                    <v-list-item-subtitle
                      style="font-size: 12px; color: #222b4c"
                      class="text-wrap"
                    >
                    {{
                        member.headOffice != '66fb2d896646ca001cb93b3f' ? 
                        member.directoryData.name || member.name + " " + member.firstLastname :
                        member.directoryData.company || member.company || "N/D"
                      }}
                      <br />
                      {{ member.directoryData.email || member.email }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <div
                  class="my-2 mx-4 pl-10 body-2"
                  v-if="
                    member.headOffice === '62b622c66a7441ae20159d7d' ||
                    member.headOffice === '6297b0fec7bf8b6f826313be'
                  "
                >
                  <span
                    v-for="i in 5"
                    :key="i"
                    class="mdi"
                    :class="starClass(i, member)"
                  ></span>
                </div>
              </v-col>
            </v-row>
          </v-img>
          <v-list-item two-line>
            <v-list-item-content>
              <p style="font-weight: 900">
                {{ getMemberBranch(member) }}
              </p>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      class="px-5 pb-5 mb-2"
      justify="center"
      v-if="micrositeMembers.length == 0 && noResults"
    >
      <span>No se han encontrado resultados</span>
    </v-row>
  </v-container>
</template>

<script>
import { colors } from "@/constants/colors";
import { mapState } from "vuex";
import { mapActions, mapMutations } from "vuex";

export default {
  name: "MyDirectoryMembers",
  props: {
    color: {
      type: String,
      default: "#000",
    },
    profilePicture: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      search: "",
      totalPages: 0,
      membersPage: 1,
      micrositeMembers: [],
      items: [10, 20, 30, 40, 50],
      itemsToGet: 20,
      noResults: false,
      branches: [],
      branch: null,
      roles: [
        { text: "Asociado", value: "MEMBER" },
        { text: "Afiliado Vinculado", value: "MEMBERSHIP_USER" },
      ],
      role: null,
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    organizationId() {
      const imefAfiliationId = "67224410633a0427c814fa7e"; // 62d707d0cdc3d0001b08cb84
      return imefAfiliationId;
    },
    orderedBranches() {
      return this.branches.sort((a, b) => {
        if (a.company < b.company) {
          return -1;
        }
        if (a.company > b.company) {
          return 1;
        }
        return 0;
      });
    },
  },
  methods: {
    ...mapActions("microsite", [
      "getMicrositeMembers",
      "getMicrositeMembersSearch",
    ]),
    ...mapMutations("loader", ["loading", "loaded"]),
    ...mapMutations("notification", ["show", "hide"]),

    getMemberBranch(member) {
      let finalString = "Miembro de "
      // para usuarios de imef, retornar grupo dentro de memberData
      if (["66fb2d896646ca001cb93b3f"].includes(this.user.headOffice.id)) {
        let index = [...member?.memberData].findIndex(
          (elem) => elem.formattedName === 'grupo'
        );
        if (index === -1) return "N/D";
        return String(finalString + String(member?.memberData[index].value)).toUpperCase();
      }
      // retornar branch para usuarios default
       return member.branch ? finalString + member.branch.company : "N/D";
    },

    starClass(index, member) {
      return {
        "mdi-star": index <= member.starRating,
        "mdi-star-outline": index > member.starRating,
        "text-custom-orange": true,
      };
    },
    async setStars(member) {
      let starRating = 0;
      member.starRating = 0;

      if (
        member.name != "" &&
        member.firstLastname != "" &&
        member.email != "" &&
        //member.phone != undefined &&
        member.memberData[0].value != "" &&
        //member.memberInformation.codigo_postal != "N/D" &&
        member.memberData[1].value != "" &&
        //member.memberInformation.calle != "N/D" &&
        member.memberData[2].value != "" &&
        //member.memberInformation.num_ext != "N/D" &&
        member.memberData[3].value != "" &&
        //member.memberInformation.colonia != "N/D" &&
        member.memberData[5].value != "" &&
        //member.memberInformation.municipio != "N/D" &&
        member.memberData[6].value != "" &&
        //member.memberInformation.estado != "N/D"
        member.memberData[18].value != ""
      ) {
        member.starRating = member.starRating + 1;

        if (
          //member.memberInformation.fecha_de_cumpleanos != "N/D" &&
          member.memberData[9].value != "" &&
          //member.memberInformation.grado_maximo_de_estudios != "N/D" &&
          member.memberData[11].value != "" &&
          //member.memberInformation.gender != "N/D" &&
          member.memberData[10].value != "" &&
          //member.memberInformation.comprobanteDomicilio != "NOT_ASSIGNED"
          member.memberData[15].value != ""
        ) {
          member.starRating = member.starRating + 1;

          if (
            //member.memberInformation.curp != "N/D" &&
            member.memberData[7].value != "" &&
            // member.memberInformation.rfc != "N/D" &&
            member.memberData[8].value != "" &&
            //member.memberInformation.constanciaSituacionFiscal != "NOT_ASSIGNED" &&
            member.memberData[13].value != "" &&
            //member.memberInformation.ine != "NOT_ASSIGNED"
            member.memberData[14].value != ""
          ) {
            member.starRating = member.starRating + 1;

            if (
              //member.memberInformation.pagina_web != "N/D" //&&
              //member.memberInformation.companyImage != "N/D"

              member.memberData[16].value != "" &&
              member.memberData[16].value != null
            ) {
              member.starRating = member.starRating + 1;

              if (
                member.memberData[19].value != "" &&
                member.memberData[19].value != null
              ) {
                member.starRating = member.starRating + 1;
              }
            }
          }
        }
      }
    },

    async getMembersSearch() {
      this.noResults = false;
      try {
        this.loading();
        const members = await this.getMicrositeMembersSearch({
          id: this.organizationId,
          page: this.membersPage,
          perPage: this.itemsToGet,
          name: this.search,
          role: this.role,
          branch: this.branch,
        });

        if (members.status) {
          this.micrositeMembers = members.data.members;
          if (this.micrositeMembers.length == 0) {
            this.noResults = true;
          }
          this.totalPages = members.data.totalPages;
        } else {
          console.log(members.message);
          this.show({
            text: "¡No autorizado!",
            color: "error",
          });
        }

        const superMember = members.data.members;
        for (let i = 0; i < superMember.length; i++) {
          const member = superMember[i];
          this.setStars(member);
        }
      } catch (error) {
        console.error(
          "Error al obtener miembros:  ===================================="
        );
        console.log(error);
      } finally {
        this.loaded();
      }
    },

    async getBranchesByMembershipId(organizationId) {
      console.log("organizationId ==== ", organizationId);
      try {
        const branches = await this.axios.get(
          `branches/membership/${organizationId}`
        );
        if (branches.status) {
          this.branches = branches.data.branches;
        } else {
          console.log("Error en respuesta de branches:", branches.message);
        }
      } catch (error) {
        console.error(
          "Error al obtener sucursales:",
          error.response ? error.response.data : error.message
        );
      }
    },

    searchMembers() {
      this.membersPage = 1;
      this.micrositeMembers = [];
      this.getMembersSearch();
    },
  },
  created() {
    this.getMembersSearch();
    this.getBranchesByMembershipId(this.organizationId);
  },
};
</script>

<style>
@media screen and (min-width: 960px) {
  .absolute-right {
    position: absolute !important;
    right: 0 !important;
  }
}

.text-custom-orange {
  color: orange;
}
</style>