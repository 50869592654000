<!--Vista que sirve para iniciar sesión-->
<template>
  <v-container fluid class="pb-0 px-0 login-form d-flex align-space-between flex-column">
    <v-form ref="signInForm" v-model="validSignInForm" lazy-validation>
      <!--INICIO Nuevo Login-->
      <v-row justify="center">
        <v-col cols="12" md="10">
          <v-row justify="center" justify-md="end">
            <v-col cols="11" md="10">
              <v-tabs :color="color.primaryVariant" right @change="changeUserType()"
                :background-color="color.background" active-class="active-white" show-arrows :value="tabValue">
                <v-tab><span class="body-2">Iniciar como organización</span></v-tab>
                <v-tab><span class="body-2">Iniciar como miembro</span></v-tab>

                <v-tab-item>
                  <v-row>
                    <v-col cols="12" md="7">
                      <div :class="$vuetify.breakpoint.smAndDown
        ? 'text-center ma-2 pa-2 mt-5'
        : 'ma-10 pa-10'
      ">
                        <span class="display-2 font-weight-bold">¡Hola!</span>
                        <br />
                        <br />
                        <br />
                        <span class="title font-weight-regular">
                          Puedes crear una cuenta o iniciar sesión como
                          organización
                        </span>
                      </div>
                    </v-col>

                    <v-col cols="12" md="5">
                      <v-row class="py-10 mx-0">
                        <v-col cols="12">
                          <v-text-field v-model="dataUser.email" type="email" solo dense
                            :background-color="color.background" hide-details="auto" flat
                            prepend-inner-icon="mdi-domain" :rules="emailRules" @keyup.enter="_login()">
                            <template v-slot:label>
                              <span class="body-2">Correo electrónico de organización</span>
                            </template>
                          </v-text-field>
                        </v-col>

                        <v-col cols="12" class="py-0">
                          <v-text-field v-model="dataUser.password" :type="showPass ? 'text' : 'password'" solo dense
                            :background-color="color.background" hide-details="auto" flat prepend-inner-icon="mdi-lock"
                            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPass = !showPass"
                            :rules="passwordRules" @keyup.enter="_login()">
                            <template v-slot:label>
                              <span class="body-2">Contraseña</span>
                            </template>
                          </v-text-field>
                        </v-col>

                        <v-col cols="12">
                          <v-btn class="font-weight-bold black--text mt-10 body-2 text-capitalize" color="accent"
                            rounded block @click="_login()" :loading="loadingButton" :disabled="dataUser.email == '' ||
      dataUser.password == '' ||
      !validSignInForm
      ">Ingresar</v-btn>
                        </v-col>
                        <v-col cols="12" v-if="dataUser.userType == 'ORG'">
                          <v-btn class="font-weight-bold black--text body-2 text-lowercase" color="secondary" rounded
                            block to="/registro">
                            <span class="text-capitalize">O</span>&nbsp;crea una
                            cuenta como organización
                          </v-btn>
                        </v-col>
                        <v-col cols="12" class="text-center">
                          <p class="body-2 my-0 font-weight-bold">
                            ¿Has olvidado tu contraseña?
                            <span class="body-2 myy-0 text-decoration-underline clickable"
                              @click="passwordRecover()">Haz clic aquí</span>
                          </p>
                        </v-col>
                        <v-col cols="12" class="text-center" v-if="$vuetify.breakpoint.smAndDown">
                          <p class="body-2 my-0 font-weight-bold">
                            ¿No eres una organización? Inicia sesión como
                            miembro
                            <span class="body-2 myy-0 text-decoration-underline clickable" @click="changeUserType()">
                              aquí
                            </span>
                          </p>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-tab-item>

                <v-tab-item>
                  <v-row>
                    <v-col cols="12" md="7">
                      <div :class="$vuetify.breakpoint.smAndDown
        ? 'text-center ma-2 pa-2 mt-5'
        : 'ma-10 pa-10'
      ">
                        <span class="display-2 font-weight-bold">¡Hola!</span>
                        <br />
                        <br />
                        <br />
                        <span class="title font-weight-regular">
                          Puedes ingresar como miembro activo de alguna de las
                          organizaciones registradas
                        </span>
                      </div>
                    </v-col>

                    <v-col cols="12" md="5">
                      <v-row class="py-10 mx-0">
                        <v-col cols="12">
                          <v-text-field v-model="dataUser.email" type="email" solo dense
                            :background-color="color.background" hide-details="auto" flat
                            prepend-inner-icon="mdi-account-circle-outline" :rules="emailRules" @keyup.enter="_login()">
                            <template v-slot:label>
                              <span class="body-2">Correo electrónico de miembro</span>
                            </template>
                          </v-text-field>
                        </v-col>

                        <v-col cols="12" class="py-0">
                          <v-text-field v-model="dataUser.password" :type="showPass ? 'text' : 'password'" solo dense
                            :background-color="color.background" hide-details="auto" flat prepend-inner-icon="mdi-lock"
                            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPass = !showPass"
                            :rules="passwordRules" @keyup.enter="_login()">
                            <template v-slot:label>
                              <span class="body-2">Contraseña</span>
                            </template>
                          </v-text-field>
                        </v-col>

                        <v-col cols="12">
                          <v-btn class="font-weight-bold black--text mt-10 body-2 text-capitalize" color="accent"
                            rounded block @click="_login()" :loading="loadingButton" :disabled="dataUser.email == '' ||
      dataUser.password == '' ||
      !validSignInForm
      ">Ingresar</v-btn>
                        </v-col>
                        <v-col cols="12" class="text-center">
                          <p class="body-2 my-0 font-weight-bold">
                            ¿Has olvidado tu contraseña?
                            <span class="body-2 myy-0 text-decoration-underline clickable"
                              @click="passwordRecover()">Haz clic
                              aquí</span>
                          </p>
                        </v-col>
                        <v-col cols="12" class="text-center" v-if="$vuetify.breakpoint.smAndDown">
                          <p class="body-2 my-0 font-weight-bold">
                            ¿Eres una organización? Inicia sesión
                            <span class="body-2 myy-0 text-decoration-underline clickable" @click="changeUserType()">
                              aquí
                            </span>
                          </p>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>

    <v-spacer></v-spacer>
    <!--INICIO Dialog que muesta el estatus del usuario-->
    <v-dialog v-model="userStatusDialog" width="600">
      <v-card>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="10" md="7" class="mt-8 d-flex justify-center">
              <v-img class="" src="@/assets/images/weChamber/logo-WC-main-nav-drawer.svg" max-width="220" height="auto"
                contain></v-img>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="mt-2">
              <p v-if="userStatus == 'TO_VALIDATE'" class="text-center font-weight-bold headline mb-0">
                Validación de correo
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <p v-if="userStatus == 'TO_VALIDATE'" class="title text-center">
                El correo del usuario aún no está validado, puede validarlo
                enviando un correo de validación con el botón de "Validar
                correo".
              </p>
              <p v-if="userStatus == 'SUSPENDED'" class="title text-center">
                El usuario ha sido suspendido, no puedes iniciar sesión.
              </p>
              <p v-if="userStatus == 'DELETED'" class="title text-center">
                El usuario ha sido eliminado, no puedes iniciar sesión.
              </p>
            </v-col>
          </v-row>
          <v-row class="d-flex pb-2 mt-5" :class="$vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-center'
      ">
            <v-btn class="font-weight-bold black--text mx-2" color="grey lighten-3" rounded elevation="0"
              @click="userStatusDialog = false">
              Cerrar
            </v-btn>
            <v-btn v-if="userStatus == 'TO_VALIDATE'" class="font-weight-bold black--text mx-2" color="accent" rounded
              elevation="0" @click="$_resendEmailValidation()">
              Validar correo
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--FIN Dialog que muesta el estatus del usuario-->
  </v-container>
</template>
<script>
import { mapMutations, mapActions, mapState } from "vuex";
import { validations } from "@/share/validations.js";
import { colors } from "@/constants/colors.js";
export default {
  data() {
    return {
      loadingButton: false,
      validSignInForm: false,
      showPass: false,
      dataUser: {
        userType: "MEMBER",
      },
      userStatus: "",
      userStatusDialog: false,
      emailRules: validations.emailValidation({
        required: true,
      }),
      passwordRules: validations.generalFValidation({
        required: true,
        name: "contraseña",
      }),
      color: colors,
    };
  },
  methods: {
    ...mapActions("authentication", ["resendEmailValidation"]),
    ...mapActions("user", ["login", "realoadUserData"]),
    ...mapMutations("notification", ["show"]),
    ...mapMutations("user", ["setToken"]),
    ...mapMutations("loader", ["loading", "loaded"]),
    changeUserType() {
      (this.dataUser = {
        userType: this.dataUser.userType == "MEMBER" ? "ORG" : "MEMBER",
      }),
        this.$refs.signInForm.reset();
    },
    /**
     * Función para validar que los campos del formulario de usuario hayan sido llenados de la forma correcta.
     */
    validateSignInForm() {
      if (this.$refs.signInForm.validate()) {
        this.snackbar = true;
        return true;
      }
      return false;
    },
    async _login() {
      if (this.validateSignInForm()) {
        try {
          this.loadingButton = true;
          let response = await this.login(this.dataUser);

          if (response.status == true) {
            this.$gtag.set({ "Tipo de Usuario": response.role });
            if (
              response.role == "MEMBER" && ["62b622c66a7441ae20159d7d", "6297b0fec7bf8b6f826313be"].includes(response.organization)
            ) {
              //response.userState == 'PROSPECTO' ? this.$router.push("/member/home") : this.$router.push("/member/affiliation-profile");
              if (response.userState == 'PROSPECTO') {
                this.$router.push("/member/home")
              } else {
                const eventRedirect = this.$route.query.event || null;
                if (response.userState == "ACTIVE" && eventRedirect) {
                  this.$router.push(`/member/eventMicrosite/${eventRedirect}`);
                } else {
                  this.$router.push("/member/affiliation-profile");
                }
              }
            } else if (
              response.role == "MEMBERSHIP_USER" ||
              (response.role == "MEMBER" &&
                ![
                  "62b622c66a7441ae20159d7d",
                  "6297b0fec7bf8b6f826313be",
                ].includes(response.organization))
            ) {
              const eventRedirect = this.$route.query.event || null;
              if (response.userState == "ACTIVE" && eventRedirect) {
                this.$router.push(`/member/eventMicrosite/${eventRedirect}`);
              } else {
                this.$router.push("/member/home");
              }
            } else {
              // Revisar el estatus del usuario
              if (response.userState == "VALIDATED") {
                //this.$router.push("/mis-eventos");
                this.$router.push("/panel-inicio");
              } else {
                this.userStatus = response.userState;
                this.userStatusDialog = true;
              }
            }
          } else {
            console.log("no entra aqui");
            this.show({
              text: response.message,
              color: "error",
              time: 8000,
            });
          }
          this.loadingButton = false;
        } catch (err) {
          console.log(err);
        }
      }
    },
    async $_resendEmailValidation() {
      let response = await this.resendEmailValidation(this.dataUser.email);
      this.userStatusDialog = false;
      console.log("Esta es la respuesta");
      console.log(response);
    },
    passwordRecover() {
      this.$router.push("/recuperar-contrasena");
    },
  },
  computed: {
    ...mapState("user", ["user"]),
    tabValue() {
      return this.dataUser.userType == "MEMBER" ? 1 : 0;
    },
  },
  async created () {
    if (this.$route.params.token && this.$route.params.email) {
      this.loading();

      localStorage.setItem("USER_TOKEN", this.$route.params.token);
      this.setToken(this.$route.params.token);

      await this.realoadUserData({email: this.$route.params.email});

      this.loaded();

      if(this.$route.params.eventId){
        this.$router.push(`/member/eventMicrosite/${this.$route.params.eventId}`);
      }else{
        this.$router.push(`/member/renew/2/${this.user._id}`);
      }
    } 
  },
  mounted() {
    this.$gtag.screenview({
      app_name: "App",
      screen_name: "Login page",
    });
  },
};
</script>
<style scoped>
.login-form {
  min-height: 73vh;
}

.blueBorder {
  border: 3px solid #00aeef;
}

.greyBorder {
  border: 2px solid #e0e0e0;
}

.typeCard:hover {
  cursor: pointer;
}

.formParent {
  perspective: 1000px;
}

.form {
  transition: transform 1s;
  transform-style: preserve-3d;
  transform: rotateY(0deg);
}

.flip-form {
  transition: transform 1s;
  transform-style: preserve-3d;
  transform: rotateY(360deg);
}

.active-white {
  background-color: white !important;
}

.clickable {
  cursor: pointer !important;
}
</style>