<template>
  <v-container class="px-12 pb-12 fill-height">
    <v-row>
      <v-col cols="12" class="pt-10">
        <v-img
          v-if="msConfig.banner"
          max-height="250"
          class="rounded-xl"
          :src="msConfig.banner"
        >
        </v-img>
      </v-col>
      <v-col cols="12">
        <p class="display-1 text-center font-weight-bold">
          {{ paidInscription ? "Comprar membresía" : "Pagar Inscripción" }}
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card
          class="px-3 pt-6 pb-2"
          rounded="lg"
          elevation="0"
          :style="`border: 1.7px solid ${msConfig.primaryColor}`"
        >
          <v-row class="px-5 px-md-15">
            <!--INICIO de Paso 1 de Checkout-->
            <v-col v-if="currentStep === 1" cols="12">
              <v-row>
                <v-col cols="12" class="pb-0 pt-10">
                  <p class="subtitle-1 mb-7">
                    <span class="font-weight-bold mr-4">
                      Paso 1 de {{ user.role === "MEMBER" ? 3 : 4 }}
                    </span>
                    <span>
                      Verificar información de la
                      {{ paidInscription ? "membresía" : "Inscripción" }}
                    </span>
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  class="access-item-border"
                  v-for="level in affiliationLevel"
                  :key="level.id"
                >
                  <v-row>
                    <v-col cols="12" md="6" class="py-5 px-5 pl-md-10">
                      <p class="title font-weight-bold">
                        {{ level.name }}
                      </p>
                      <p class="body-2">{{ level.details }}</p>
                      <p
                        class="subtitle-2 mb-0"
                        v-if="!isImefOrganization(headOffice)"
                      >
                        {{ level.availableAffiliationTypesForSale }}
                        accesos disponibles.
                      </p>
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                      xl="3"
                      offset-md="1"
                      class="py-5 px-5"
                    >
                      <p class="title font-weight-bold mb-0">Precio:</p>
                      <p class="subtitle-1">
                        {{
                          totalPurchaseWithDiscount.number == 0
                            ? "Gratis"
                            : totalPurchaseWithDiscount.string
                        }}
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="mt-5">
                  <v-row align="center">
                    <v-col cols="12" md="6" lg="4">
                      <v-text-field
                        class="mr-2"
                        v-model="coupon"
                        :disabled="!isAffiliationLevelSelected || couponApplied"
                        :error="errors.coupon.error"
                        :error-messages="errors.coupon.message"
                        :success="success.coupon.success"
                        :success-messages="success.coupon.message"
                        label="Aplicar Cupón"
                        dense
                        filled
                        rounded
                        prepend-inner-icon="fas fa-tags"
                      />
                    </v-col>
                    <v-col>
                      <v-btn
                        :disabled="
                          !isAffiliationLevelSelected ||
                          !coupon ||
                          couponApplied
                        "
                        color="secondary"
                        small
                        rounded
                        elevation="0"
                        class="text-capitalize"
                        @click="addCoupon"
                      >
                        Aplicar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6" class="my-7">
                  <v-row>
                    <v-btn
                      class="mx-1"
                      color="primary"
                      text
                      rounded
                      :to="getBackToUserCheckout"
                    >
                      <span class="font-weight-bold">Cancelar</span>
                    </v-btn>
                    <v-btn
                      class="mx-1"
                      :color="msConfig.buttonColor"
                      :style="`color: ${msConfig.textButtonColor}`"
                      rounded
                      :disabled="!isAffiliationLevelSelected"
                      @click="increaseStep"
                    >
                      <span class="font-weight-bold"> Continuar </span>
                    </v-btn>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  class="d-flex justify-end align-end pb-7"
                >
                  <p class="headline mb-0">
                    <span class="font-weight-bold">Total: </span
                    >{{
                      totalPurchaseWithDiscount.number == 0
                        ? "Gratis"
                        : totalPurchaseWithDiscount.string
                    }}
                  </p>
                </v-col>
              </v-row>
            </v-col>
            <!-- PASO 2 -->
            <v-col v-if="currentStep === 2" cols="12">
              <v-row>
                <v-col cols="12" class="pb-0 pt-10">
                  <p class="subtitle-1 mb-7">
                    <span>Selecciona el método de pago</span>
                  </p>
                </v-col>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" class="pt-0">
                      <v-item-group
                        v-model="order.paymentInformation.paymentMethod"
                      >
                        <v-row justify="center">
                          <v-col cols="12">
                            <h2 class="title font-weight-bold">
                              Métodos de pago
                            </h2>
                          </v-col>
                          <template
                            v-if="
                              paymentMethods.length > 0 && activePaymentMethods
                            "
                          >
                            <v-col
                              cols="12"
                              sm="6"
                              md="4"
                              v-for="(item, index) in paymentMethods"
                              :key="index"
                            >
                              <v-item
                                v-if="item.active"
                                v-slot="{ active, toggle }"
                                :value="item.name"
                              >
                                <v-card
                                  :color="
                                    active ? msConfig.buttonColor : 'white'
                                  "
                                  class="pa-2 px-lg-3 py-lg-5 fill-height text-center"
                                  :style="`color: ${
                                    active ? msConfig.textButtonColor : ''
                                  }`"
                                  @click="
                                    paymentSelection(toggle, item.name, active)
                                  "
                                  rounded="sm"
                                  elevation="1"
                                >
                                  <v-row
                                    justify="center"
                                    align="center"
                                    class="fill-height"
                                  >
                                    <v-col cols="12">
                                      <span
                                        class="title font-weight-bold mb-5 break-word"
                                      >
                                        {{ paymentLabels[item.name] }}
                                      </span>
                                      <br />
                                      <v-icon
                                        :style="getSizePaymentsMethodsIcons"
                                        :color="
                                          active ? msConfig.textButtonColor : ''
                                        "
                                        >{{ paymentIcons[item.name] }}</v-icon
                                      >
                                    </v-col>
                                  </v-row>
                                </v-card>
                              </v-item>
                            </v-col>
                          </template>
                          <v-col v-else>
                            <h3
                              class="red--text text-center title font-weight-bold"
                            >
                              No se encontraron métodos de pago
                            </h3>
                          </v-col>
                        </v-row>
                      </v-item-group>
                    </v-col>

                    <v-col
                      v-if="
                        order.paymentInformation.paymentMethod ===
                        paymentMethodsKeys.Cash
                      "
                      cols="12"
                      class="pb-0"
                    >
                      <p class="subtitle-1 primary--text font-weight-bold">
                        <v-icon color="amber">mdi-alert</v-icon> Los pagos en
                        OXXO se ven reflejados 24 horas después de haber
                        realizado el pago.
                        <!-- por lo que recomendamos hacer el
                            pago a mas tardar 24 horas antes de que comience el
                            evento. -->
                      </p>
                      <!--<p class="subtitle-1">Los pagos en oxxo se ven reflejados 24 horas después de haber realizado el pago, por lo que recomendamos hacer el pago, a mas tardar 24 horas antes de que comience el evento.</p>-->
                    </v-col>
                    <v-col
                      v-if="
                        order.paymentInformation.paymentMethod ===
                        paymentMethodsKeys.BankTransfer
                      "
                      cols="12"
                      class="pb-0"
                    >
                      <p class="subtitle-1 primary--text font-weight-bold">
                        <v-icon color="amber">mdi-alert</v-icon> Los pagos
                        mediante transferencia bancaria se ven reflejados 24
                        horas después de haber realizado el pago.
                        <!-- por lo que
                            recomendamos hacer el pago a mas tardar 24 horas
                            antes de que comience el evento.-->
                      </p>
                      <p class="subtitle-1 primary--text font-weight-bold">
                        <v-icon color="amber">mdi-alert</v-icon>
                        Esta CLABE interbancaria es DESECHABLE, única y
                        exclusiva para esta transacción, favor de no
                        compartirla, ni guardarla en la App de tu banco. Al
                        terminar la operación se INVALIDARÁ y NO se podrá usar
                        más. Si la vuelves a usar tu dinero se podría perder.
                        <!-- por lo que
                            recomendamos hacer el pago a mas tardar 24 horas
                            antes de que comience el evento.-->
                      </p>
                      <!--<p class="subtitle-1">Los pagos en oxxo se ven reflejados 24 horas después de haber realizado el pago, por lo que recomendamos hacer el pago, a mas tardar 24 horas antes de que comience el evento.</p>-->
                    </v-col>

                    <v-col
                      cols="12"
                      class="pt-0 mt-5"
                      v-if="user.role != 'MEMBER'"
                    >
                      <v-row justify="center">
                        <v-col cols="12">
                          <h3 class="title font-weight-bold">
                            Información del comprador
                          </h3>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            label="Nombre(s)"
                            v-model="order.paymentInformation.name"
                            required
                            prepend-inner-icon="fas fa-user"
                            filled
                            rounded
                          />
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            label="Apellidos"
                            v-model="order.paymentInformation.lastName"
                            required
                            prepend-inner-icon="fas fa-user"
                            filled
                            rounded
                          />
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            label="E-mail"
                            v-model="order.paymentInformation.email"
                            required
                            prepend-inner-icon="fas fa-envelope"
                            filled
                            rounded
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      v-if="
                        order.paymentInformation.paymentMethod === 'CREDIT_CARD'
                      "
                      cols="12"
                      class="pt-0 mt-5"
                    >
                      <v-row justify="center">
                        <v-col cols="12">
                          <h3 class="title font-weight-bold">
                            Información de la tarjeta
                          </h3>
                        </v-col>
                        <v-col cols="12" md="12" sm="6">
                          <v-text-field
                            v-model="order.paymentInformation.cardName"
                            type="text"
                            name="name"
                            label="Nombre(s) del titular"
                            required
                            prepend-inner-icon="fas fa-user"
                            filled
                            rounded
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-stripe-card
                            v-model="source"
                            :api-key="apiKey"
                            :stripeAccount="stripeAccount"
                            :hidePostalCode="true"
                            :key="stripeComponentKey"
                            :disabled="!order.paymentInformation.cardName"
                            ref="stripeRef"
                          />
                        </v-col>
                        <v-col v-if="false" cols="12" class="pt-0">
                          <v-switch
                            label="Quiero pagar con meses sin intereses."
                            :color="msConfig.primaryColor"
                            hide-details
                            class="mt-0"
                          ></v-switch>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      v-if="
                        installments.plans.length > 0 &&
                        order.paymentInformation.paymentMethod === 'CREDIT_CARD'
                      "
                      cols="12"
                      class="pt-0 mt-3"
                    >
                      <v-row justiify="center">
                        <v-col cols="12" md="12" sm="6">
                          <h3 class="title font-weight-bold">
                            Meses sin intereses
                          </h3>
                        </v-col>
                        <v-col cols="12">
                          <v-card>
                            <v-list>
                              <v-list-item-group
                                v-model="installments.selected"
                              >
                                <v-list-item
                                  v-for="(item, i) in installments.plans"
                                  :key="i"
                                  :value="item"
                                >
                                  <template #default="{ active }">
                                    <v-list-item-content class="d-flex">
                                      <v-list-item-title>
                                        <v-checkbox
                                          class="mt-0"
                                          hide-details
                                          :label="`${item.count} meses`"
                                          :input-value="active"
                                        />
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </template>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" class="pb-0">
                      <h2 class="title font-weight-bold">Facturación</h2>
                    </v-col>
                    <v-col cols="12" class="d-flex">
                      <v-checkbox
                        hide-details
                        @click="declineBill = false"
                        class="mr-4"
                        v-model="acceptBill"
                        :color="msConfig.active"
                      >
                        <template v-slot:label>
                          <span class="d-flex align-center pa-0"
                            >Quiero facturar mi compra
                          </span>
                        </template>
                      </v-checkbox>
                      <v-checkbox
                        hide-details
                        class="mr-3"
                        @click="acceptBill = false"
                        v-model="declineBill"
                        :color="msConfig.active"
                      >
                        <template v-slot:label>
                          <span class="d-flex align-center pa-0"
                            >No quiero facturar mi compra
                          </span>
                        </template>
                      </v-checkbox>
                    </v-col>

                    <v-col cols="12" class="py-0 d-flex">
                      <p v-if="acceptBill">
                        <b>Nota:</b> Tendrás hasta fin de mes para generar tu
                        factura usando el ID que recibirás en el correo de
                        confirmación
                      </p>
                      <p v-else-if="declineBill">
                        <b>Atención:</b> si eliges esta opción debes estar
                        seguro, ya que no podrás facturar esta compra en el
                        futuro.
                      </p>
                      <p v-else>* Debe seleccionar una opción para continuar</p>
                    </v-col>
                    <!---->
                    <v-col
                      cols="12"
                      class="pt-0"
                      v-if="
                        [
                          '62b622c66a7441ae20159d7d',
                          '6297b0fec7bf8b6f826313be',
                        ].includes(this.headOffice)
                      "
                    >
                      <v-checkbox
                        v-model="billingPolitics.terms"
                        :color="msConfig.primaryColor"
                        hide-details
                      >
                        <template v-slot:label>
                          <span>
                            Acepto las&nbsp;
                            <router-link
                              to="/politicas-de-facturacion"
                              v-slot="{ href }"
                            >
                              <a :href="href" target="_blank" @click.stop
                                >Políticas de Facturación.
                              </a>
                            </router-link>
                          </span>
                        </template>
                      </v-checkbox>
                    </v-col>
                    <!---->
                    <!---->

                    <!---->

                    <v-col cols="12" class="pt-0">
                      <v-checkbox
                        v-model="termsOfUSe.terms"
                        :color="msConfig.primaryColor"
                        hide-details
                      >
                        <template v-slot:label>
                          <span>
                            Acepto los&nbsp;
                            <router-link
                              to="/terminos-y-condiciones"
                              v-slot="{ href }"
                            >
                              <a :href="href" target="_blank" @click.stop
                                >Términos y Condiciones
                              </a>
                            </router-link>
                            &nbsp;y el&nbsp;
                            <router-link
                              to="/aviso-de-privacidad"
                              v-slot="{ href }"
                            >
                              <a :href="href" target="_blank" @click.stop
                                >Aviso de Privacidad.</a
                              >
                            </router-link>
                          </span>
                        </template>
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="6" class="my-7">
                  <v-row>
                    <v-btn
                      class="mx-1"
                      color="primary"
                      text
                      rounded
                      @click="currentStep--"
                    >
                      <span class="font-weight-bold">Regresar</span>
                    </v-btn>
                    <v-btn
                      class="mx-1"
                      :color="msConfig.buttonColor"
                      :style="`color: ${msConfig.textButtonColor}`"
                      rounded
                      :loading="loaderContinueButton"
                      @click="executePaymentAction"
                      :disabled="disableContinueBuyButton"
                    >
                      <span class="font-weight-bold">Continuar</span>
                    </v-btn>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  class="d-flex justify-end align-end pb-7"
                >
                  <p class="headline mb-0">
                    <span class="font-weight-bold">Total: </span
                    >{{
                      totalPurchaseWithDiscount.number == 0
                        ? "Gratis"
                        : totalPurchaseWithDiscount.string
                    }}
                  </p>
                </v-col>
              </v-row>
            </v-col>
            <!-- PASO 3 CHECKOUT -->
            <v-col v-if="currentStep === 3" cols="12">
              <v-row>
                <v-col cols="12" class="pb-0 pt-10">
                  <p class="subtitle-1 mb-7">
                    <span class="font-weight-bold mr-4"
                      >Paso {{ user.role === "MEMBER" ? 3 : 4 }} de
                      {{ user.role === "MEMBER" ? 3 : 4 }} </span
                    ><span>Revisa los datos de tu compra</span>
                  </p>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <p class="headline font-weight-bold">Resumen</p>
                </v-col>
                <v-col cols="12">
                  <v-row class="text-center" justify="center">
                    <v-col cols="6" sm="3">
                      <h2 class="title font-weight-bold">Nivel de Membresía</h2>
                    </v-col>
                    <v-col cols="6" sm="3">
                      <h2 class="title font-weight-bold">Precio U.</h2>
                    </v-col>
                    <v-col cols="6" sm="3">
                      <h2 class="title font-weight-bold">Subtotal</h2>
                    </v-col>
                    <v-col cols="6" sm="3">
                      <h2 class="title font-weight-bold">Facturación</h2>
                    </v-col>
                  </v-row>
                  <v-row class="text-center" justify="center">
                    <v-col cols="6" sm="3">
                      <p class="subtitle-1 mb-0">
                        {{ affiliationLevel[0].name }}
                      </p>
                    </v-col>
                    <v-col cols="6" sm="3">
                      <p class="subtitle-1 mb-0">
                        {{
                          totalPurchaseWithDiscount.number == 0
                            ? "Gratis"
                            : totalPurchaseWithDiscount.string
                        }}
                      </p>
                    </v-col>
                    <v-col cols="6" sm="3">
                      <p class="subtitle-1 mb-0">
                        {{
                          totalPurchaseWithDiscount.number == 0
                            ? "Gratis"
                            : totalPurchaseWithDiscount.string
                        }}
                      </p>
                    </v-col>
                    <v-col cols="6" sm="3">
                      <p class="subtitle-1 mb-0">
                        {{
                          order.paymentInformation.needsInvoice ? "Si" : "No"
                        }}
                      </p>
                    </v-col>
                    <v-col cols="12">
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="order.coupon.id"
                    class="text-center"
                    align="center"
                    justify="center"
                  >
                    <v-col cols="6" sm="3">
                      <h2 class="title font-weight-bold">Cupón Aplicado</h2>
                    </v-col>
                    <v-col cols="6" sm="3">
                      <h2 class="title font-weight-bold">
                        Porcentaje de Descuento
                      </h2>
                    </v-col>
                    <v-col cols="6" sm="3">
                      <h2 class="title font-weight-bold">
                        Cantidad de Descuento
                      </h2>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="order.coupon.id"
                    class="text-center"
                    justify="center"
                  >
                    <v-col cols="6" sm="3">
                      <p class="subtitle-1 mb-0">
                        {{ order.coupon.code }}
                      </p>
                    </v-col>
                    <v-col cols="6" sm="3">
                      <p class="subtitle-1 mb-0">
                        {{ order.coupon.percentOff }}%
                      </p>
                    </v-col>
                    <v-col cols="6" sm="3">
                      <p class="subtitle-1 mb-0">
                        {{ discount.string }}
                      </p>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" md="6" class="my-9">
                  <v-row>
                    <v-btn
                      class="mx-1"
                      color="primary"
                      text
                      rounded
                      :to="getBackToUserCheckout"
                      ><span class="font-weight-bold">Regresar</span></v-btn
                    >
                    <v-btn
                      class="mx-1"
                      :color="msConfig.buttonColor"
                      :style="`color: ${msConfig.textButtonColor}`"
                      rounded
                      @click="finishBuying"
                    >
                      <span class="font-weight-bold">Finalizar</span>
                    </v-btn>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  class="d-flex justify-end align-end pb-7"
                >
                  <p class="headline mb-0">
                    <span class="font-weight-bold">Total: </span
                    >{{
                      totalPurchaseWithDiscount.number == 0
                        ? "Gratis"
                        : totalPurchaseWithDiscount.string
                    }}
                  </p>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="currentStep === 4" cols="12">
              <v-row>
                <v-col cols="12" class="text-center">
                  <v-row justify="center" class="mb-5">
                    <v-icon :style="getSizeFinalIcon"
                      >mdi-email-newsletter</v-icon
                    >
                  </v-row>
                </v-col>
                <v-col cols="12" class="text-center pt-0">
                  <p class="headline font-weight-bold text-uppercase">
                    ¡Muchas gracias!
                  </p>
                  <!-- Para pagos con tarjeta, que usaron cupón/corteskía del 100% o el boleto costaba $0.00 -->
                  <p
                    v-if="
                      totalPurchase.number == 0 ||
                      order.paymentInformation.paymentMethod ==
                        paymentMethodsKeys.CreditCard
                    "
                    class="subtitle-1 mb-3"
                  >
                    <span v-if="!paidInscription">
                      El pago de inscripción se ha realizado exitosamente.
                      Consulte el recibo de su compra en su correo electrónico.
                    </span>
                    <span v-else>
                      El pago de la membresía se ha realizado exitosamente.
                      Consulte el recibo de su compra en su correo electrónico.
                    </span>
                  </p>
                  <!-- Para cualquier tipo de registro -->
                  <p class="subtitle-1 mb-3" style="color: #17c6e9">
                    En ocasiones tu configuración de seguridad envía algunos
                    correos a SPAM, no olvides revisar ahí.
                  </p>
                  <p
                    v-if="
                      order.paymentInformation.needsInvoice &&
                      totalPurchaseWithDiscount.number != 0
                    "
                    class="subtitle-1"
                  >
                    El id de tu orden es el siguiente <b>{{ order.id }}</b
                    >, te recomendamos guardarlo, ya que te servirá para generar
                    la factura de tu compra{{
                      order.paymentInformation.paymentMethod !=
                      paymentMethodsKeys.CreditCard ? ' una vez realizado tu pago' : ''
                    }}.
                  </p>
                  <div
                    v-if="
                      order.paymentInformation.needsInvoice &&
                      totalPurchaseWithDiscount.number != 0
                    "
                  >
                    <p
                      v-if="
                        [
                          '62b622c66a7441ae20159d7d',
                          '6297b0fec7bf8b6f826313be',
                        ].includes(headOffice) &&
                        order.coupon.percentOff != 100 &&
                        order.paymentInformation.needsInvoice
                      "
                      class="subtitle-1 mb-3"
                    >
                      <v-icon color="red">mdi-alert</v-icon>
                      Tendrás hasta el final del día de hoy (11:59 pm) para
                      generar tu factura usando el ID que recibirás en el correo
                      de confirmación.
                    </p>
                    <v-btn
                      v-if="order.paymentInformation.paymentMethod == paymentMethodsKeys.CreditCard"
                      large
                      class="mx-1 font-weight-bold my-2 mb-6 pa-5"
                      :color="msConfig.buttonColor"
                      :style="`color: ${msConfig.textButtonColor}`"
                      target="_blank"
                      :href="`/facturacion-cliente/${order.id}`"
                      rounded
                    >
                      Generar Factura
                    </v-btn>
                  </div>
                  <p
                    class="headline text-uppercase font-weight-bold"
                    :class="order.total === '0' ? 'text-center' : ''"
                  >
                    Resumen
                  </p>
                  <h4
                    v-if="
                      order.paymentInformation.paymentMethod ===
                      paymentMethodsKeys.BankTransfer
                    "
                    style="color: #ff0000"
                  >
                    Recuerda que tienes hasta las {{ dateExpireOrder }} para
                    realizar tu pago.
                  </h4>
                </v-col>
                <v-col cols="12">
                  <v-row class="text-center" justify="center">
                    <v-col cols="12" sm="6">
                      <h2 class="title font-weight-bold">Concepto</h2>
                    </v-col>
                    <v-col cols="6" sm="3">
                      <h2 class="title font-weight-bold">Facturación</h2>
                    </v-col>
                    <v-col cols="6" sm="3">
                      <h2 class="title font-weight-bold">Subtotal</h2>
                    </v-col>
                  </v-row>
                  <v-row class="text-center mb-0" justify="center">
                    <v-col cols="12" sm="6">
                      <p class="subtitle-1 mb-0">
                        {{ affiliationLevel[0].name }}
                      </p>
                    </v-col>
                    <v-col cols="6" sm="3">
                      <p class="subtitle-1 mb-0">
                        {{
                          order.paymentInformation.needsInvoice ? "Si" : "No"
                        }}
                      </p>
                    </v-col>
                    <v-col cols="6" sm="3">
                      <p class="subtitle-1 mb-0">
                        {{
                          totalPurchaseWithDiscount.number == 0
                            ? "Gratis"
                            : feeBreakdown
                            ? totalPurchaseWithDiscount.number - fixedFee
                            : totalPurchaseWithDiscount.string
                        }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-row v-if="order.coupon.id" class="d-flex flex-column">
                    <v-col cols="12">
                      <v-divider />
                    </v-col>
                    <v-row
                      v-if="order.coupon.id"
                      class="text-center"
                      align="center"
                      justify="center"
                    >
                      <v-col cols="6" sm="3">
                        <h2 class="title font-weight-bold">Cupón Aplicado</h2>
                      </v-col>
                      <v-col cols="6" sm="3">
                        <h2 class="title font-weight-bold">
                          Porcentaje de Descuento
                        </h2>
                      </v-col>
                      <v-col cols="6" sm="3">
                        <h2 class="title font-weight-bold">
                          Cantidad de Descuento
                        </h2>
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="order.coupon.id"
                      class="text-center"
                      justify="center"
                    >
                      <v-col cols="6" sm="3">
                        <p class="subtitle-1 mb-0">
                          {{ order.coupon.code }}
                        </p>
                      </v-col>
                      <v-col cols="6" sm="3">
                        <p class="subtitle-1 mb-0">
                          {{ order.coupon.percentOff }}%
                        </p>
                      </v-col>
                      <v-col cols="6" sm="3">
                        <p class="subtitle-1 mb-0">
                          {{ discount.string }}
                        </p>
                      </v-col>
                    </v-row>
                  </v-row>
                  <v-divider
                    v-if="feeBreakdown && fixedFee"
                    class="mb-4"
                  ></v-divider>
                  <v-row
                    v-if="feeBreakdown && fixedFee"
                    class="text-center"
                    justify="center"
                  >
                    <v-col cols="6" sm="6">
                      <h2 class="subtitle-1">Comisión de la plataforma</h2>
                    </v-col>
                    <v-col cols="6" sm="3" offset-sm="3" offset="0">
                      <h2 class="subtitle-1">{{ fixedFee }}</h2>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="feeBreakdown && fixedFee"
                    class="text-center"
                    justify="center"
                  >
                    <v-col cols="6" sm="6">
                      <h2 class="title font-weight-bold">Total</h2>
                    </v-col>
                    <v-col cols="6" sm="3" offset-sm="3" offset="0">
                      <h2 class="title font-weight-bold">
                        {{
                          totalPurchaseWithDiscount.number == 0
                            ? "Gratis"
                            : totalPurchaseWithDiscount.string
                        }}
                      </h2>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row> </v-row>
            </v-col>
          </v-row>
        </v-card>
        <v-col v-if="currentStep === 4" cols="12" class="d-flex justify-end">
          <v-row>
            <v-col cols="6" class="d-flex justify-start pt-3">
              <v-btn
                class="mx-1"
                color="primary"
                text
                rounded
                @click="decreaseStep"
              >
                <span class="font-weight-bold">Volver</span>
              </v-btn>
            </v-col>

            <v-col cols="6" class="d-flex justify-end pt-3">
              <v-btn
                class="mx-1"
                color="orange"
                :style="{ color: 'white' }"
                text
                rounded
                @click="goToHome"
              >
                <span class="font-weight-bold">Ir a mi perfil</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-col>
    </v-row>

    <PersonalizedDialog
      :dialog="voucherDialog"
      @save="closeVoucherDialog"
      hide-name
      hide-button
      hide-image
    >
      <template #alertContent>
        <voucherCard
          :instructions="bankTransferInstructions"
          :description="bankTransferDescription"
        ></voucherCard>
      </template>
      <template #button> Finalizar </template>
    </PersonalizedDialog>

    <v-dialog v-model="facturaDialog" persistent max-width="400">
      <v-card>
        <v-card-text class="px-1 rounded-xl">
          <v-container>
            <v-row justify="center">
              <v-col cols="12" class="pt-8">
                <v-row justify="center">
                  <v-icon :color="msConfig.primaryColor" :style="getBigSize"
                    >fas fa-question-circle</v-icon
                  >
                </v-row>
              </v-col>
              <v-col cols="12" class="text-center">
                <h2 class="headline font-weight-bold">
                  ¿Cómo obtengo mi factura?
                </h2>
                <p>
                  Solo debes registrar tus datos fiscales en este apartado.
                  Recibirás de forma automática tu factura vía correo
                  electrónico una vez finalizada tu compra.
                </p>
                <v-row justify="center" class="pt-3">
                  <v-btn
                    color="primary"
                    @click="(factura = true), (facturaDialog = false)"
                    rounded
                    >Aceptar</v-btn
                  >
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="5000" :color="'error'" right top>
      <v-icon color="white">mdi-domain</v-icon>

      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <modal-template
      :open="isOpenErrorCardModal"
      width="600"
      :secondaryBtnProps="{
        show: true,
        text: 'Cerrar',
        textColor: 'white',
        color: 'accent',
      }"
      @close="closeErrorCardModal"
    >
      <template #content>
        <v-row justify="center">
          <v-col cols="10" md="7" class="mt-8 d-flex justify-center">
            <v-img
              class=""
              src="@/assets/images/weChamber/logo-WC-microsite.svg"
              max-width="60"
              height="auto"
              contain
            ></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="mt-2">
            <p class="text-center font-weight-bold title mb-0">
              No se ha podido finalizar el pago
            </p>
          </v-col>
          <v-col cols="12" class="py-0">
            <p class="subtitle-1 text-center mb-0">{{ errorMessage }}</p>
          </v-col>
          <v-col cols="12" class="pt-0" v-if="errorCode != 'incorrect_cvc'">
            <p
              class="subtitle-1 text-center mb-0 secondary--text font-weight-bold"
            >
              {{ errorCode }}
            </p>
          </v-col>
        </v-row>
        <v-row v-if="errorCode != ''">
          <v-col cols="12">
            <p class="subtitle-1 text-center">
              Para más información sobre el código de error, puede ingresar al
              siguiente link:
              <a href="https://stripe.com/docs/error-codes" target="_blank"
                >Códigos de error de pago con tarjetas</a
              >
            </p>
          </v-col>
        </v-row>
      </template>
    </modal-template>
  </v-container>
</template>
<script>
import VStripeCard from "@/components/v-stripe-elements/lib/VStripeCard";
import { validations } from "@/share/validations";
import Endpoints from "@/share/Endpoints";
import { formatPrice } from "@/utils";
import { mapMutations, mapState, mapActions } from "vuex";
import CardErrors from "@/share/cardErrorCodes";
import ModalTemplate from "@/components/shared/ModalTemplate";
import moment from "moment";
import Vue from "vue";
import BillingTerms from "../../components/dashboard/billing/billingTerms.vue";
import PersonalizedDialog from "../../components/shared/PersonalizedDialog.vue";
import voucherCard from "../../components/shared/customerInstructions/voucherCard.vue";
import { isImefOrganization } from "../../share/imef";

export default {
  props: {
    affiliationId: String,
    affiliationType: String,
    membershipId: String,
    paymentType: String,
  },
  components: {
    VStripeCard,
    ModalTemplate,
    BillingTerms,
    PersonalizedDialog,
    voucherCard,
  },
  data() {
    return {
      errorMessage: "",
      errorCode: "",
      acceptBill: false,
      declineBill: false,
      hasStripeConfig: false,
      couponApplied: false,
      organizationStripeConnectId: "",
      voucherDialog: false,
      bankTransferInstructions: {},
      bankTransferDescription: "",
      loaderContinueButton: false,
      userData: {},
      typeOfOrder: "",
      source: null,
      affiliationLevel: [
        {
          inscriptionConfig: {},
        },
      ],
      errors: {
        coupon: {
          error: false,
          message: "",
        },
      },
      success: {
        coupon: {
          success: false,
          message: "",
        },
      },
      currentStep: 1,
      coupon: "",
      panel: [0],
      billingPolitics: {
        terms: false,
        receiveInfo: false,
      },

      termsOfUSe: {
        terms: false,
        receiveInfo: false,
      },
      fieldsValidations: {
        name: validations.nameValidation({
          required: true,
        }),
        lastName: validations.nameValidation({
          name: "apellidos",
          required: true,
        }),
        email: validations.emailValidation({
          required: true,
        }),
        required: validations.requiredMValidation(),
      },
      factura: false,
      facturaDialog: false,
      ticketsToBuy: 0,
      maxTicketsUserCanBuy: 5,
      msConfig: {
        banner: "",
        primaryColor: "black",
        buttonColor: "accent",
        textButtonColor: "white",
      },
      tickets: [],
      headOffice: "",
      paymentMethods: [],
      organizationId: "",
      paymentMethodsKeys: {
        Cash: "CASH",
        CreditCard: "CREDIT_CARD",
        BankTransfer: "BANK_TRANSFER",
      },
      paymentLabels: {
        CASH: "Efectivo (Oxxo)",
        CREDIT_CARD: "Tarjeta de Crédito / Débito",
        BANK_TRANSFER: "Transferencia Bancaria SPEI",
      },
      paymentIcons: {
        CASH: "fas fa-money-bill",
        CREDIT_CARD: "fas fa-credit-card",
        BANK_TRANSFER: "fas fa-landmark",
      },
      installments: {
        selected: null,
        plans: [],
        enable: false,
      },
      affiliationInstallmentSelected: [],
      affiliateInformation: {
        name: "",
        lastName: "",
        email: "",
        confirmEmail: "",
        registrationFields: {},
      },
      // Objecto que guardará los datos de la transacción
      order: {
        status: "",
        id: "",
        coupon: {
          id: "",
          percentOff: 0,
          limit: null,
          endDate: "",
          applications: 0,
        },
        itemId: null,
        paymentInformation: {
          name: "",
          lastName: "",
          email: "",
          paymentMethod: null,
          needsInvoice: false,
          cardName: "",
        },
      },
      paidInscription: false,
      inscription: {},
      cardPaymentIntentId: "",
      total: 0,
      snackbar: false,
      snackbarText: "PlaceHolder",
      stripeComponentKey: 0,
      clientSecret: "",
      apiKey: process.env.VUE_APP_STRIPE_API_KEY,
      isOpenErrorCardModal: false,
      stripeAccount: "",
      feeBreakdown: false,
      fixedFee: null,
    };
  },
  watch: {
    currentStep(current) {
      const paymentStep = 2;
      if (current === paymentStep) {
        const { name, lastName, email } = this.order.paymentInformation;
        !name &&
          (this.order.paymentInformation.name = this.affiliateInformation.name);
        !lastName &&
          (this.order.paymentInformation.lastName =
            this.affiliateInformation.lastName);
        !email &&
          (this.order.paymentInformation.email =
            this.affiliateInformation.email);

        //            DEJAR
        if (this.totalPurchaseWithDiscount.number === 0) {
          this.increaseStep();
        }
        this.cardPaymentIntentId = "";
      }
    },
    source: async function () {
      //console.log("Hola4")
      const { card, stripe } = this.$refs.stripeRef;
      console.log(this.order);
      this.loaderContinueButton = true;
      try {
        const { paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card,
          billing_details: {
            name: this.order.paymentInformation.cardName,
            email: this.order.paymentInformation.email,
          },
        });
        console.log(paymentMethod.card.country);
        const { data } = await this.axios({
          method: "post",
          url: Endpoints.cardPayementIntent,
          data: {
            paymentMethod: {
              id: paymentMethod.id,
              total: this.totalPurchaseWithDiscount.number,
            },
            orderId: this.order.id,
            cardCountry: paymentMethod.card.country,
            // organization: this.order.organizationId,
          },
        });

        if (
          this.installments.enable &&
          data.paymentIntent.availablePlans.length != 0
        ) {
          this.installments.plans = this.affiliationInstallmentSelected;
        }

        this.cardPaymentIntentId = data.paymentIntent.id;
      } catch (error) {
        console.log(error);
      } finally {
        this.loaderContinueButton = false;
      }
    },
  },
  methods: {
    ...mapMutations("loader", ["loading", "loaded"]),
    ...mapActions("user", ["realoadUserData"]),
    ...mapMutations("notification", ["show"]),
    isImefOrganization,
    reload() {
      this.realoadUserData({ email: this.user.email });
    },

    async goToHome() {
      await this.realoadUserData({ email: this.user.email });

      this.$router.push("/member/home");
    },

    async increaseStep() {
      this.currentStep++;
    },

    decreaseStep() {
      if (
        this.currentStep == 2 &&
        this.totalPurchaseWithDiscount.number === 0
      ) {
        this.currentStep -= 2;
      } else {
        this.currentStep--;
      }
    },

    closeErrorCardModal() {
      this.isOpenErrorCardModal = false;
      this.errorMessage = "";
      this.errorCode = "";
    },

    formatAmount(amount) {
      let formattedAmount = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      formattedAmount += ".00";
      return formattedAmount;
    },

    formattedAffiliationLevels(affiliationLevels) {
      return affiliationLevels.map((level) => {
        if (level.hasOwnProperty("inscriptionConfig")) {
          level.inscriptionConfig.formattedPrice = formatPrice(
            level.inscriptionConfig.price
          );
        }
        return {
          ...level,
          formattedPrice: formatPrice(level.price),
        };
      });
    },

    async getAffiliationInfo() {
      try {
        this.loading();
        let url =
          Endpoints.checkoutAffiliationGetInfo.replace(
            /:affiliationId/,
            this.affiliationId
          ) +
          "/" +
          this.affiliationType; ///valida al membership
        const response = await this.axios.get(
          `checkout/affiliation-type/${this.affiliationId}/${this.affiliationType}/${this.membershipId}`
        );
        if (response.status === 200) {
          const {
            banner,
            micrositeConfiguration: {
              primaryColor,
              buttonColor,
              textButtonColor,
            },
            affiliationTypes,
            paymentMethods,
            feeBreakdown,
            installments = false,
            installmentsSelected = [],
            organization: {
              stripeConnectId,
              id,
              headOffice,
              stripeAccountType,
              fixedFee,
            },
          } = response.data.checkoutInformation;
          this.msConfig = {
            banner,
            primaryColor,
            buttonColor,
            textButtonColor,
          };
          const index = affiliationTypes.findIndex(
            (level) => level._id === this.affiliationType
          );
          this.affiliationLevel = this.formattedAffiliationLevels([
            affiliationTypes[index],
          ]);
          this.typeOfOrder = !headOffice ? "HEAD_OFFICE" : "BRANCH";
          this.userData = { ...response.data.userData };
          this.organizationId = id;
          this.installments.enable = installments;
          this.affiliationInstallmentSelected = [...installmentsSelected];
          this.headOffice = headOffice != undefined ? headOffice : id;
          this.stripeAccount =
            stripeAccountType == "STANDARD" ? stripeConnectId : null;
          (this.organizationStripeConnectId = stripeConnectId),
            (this.paidInscription = this.paidInscriptionFunction(
              this.userData.validationStatus
            ));
          this.paymentMethods = paymentMethods;
          this.feeBreakdown = feeBreakdown;
          this.fixedFee = fixedFee ? fixedFee : null;
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        this.loaded();
      }
    },

    paidInscriptionFunction(status) {
      if (status == "ACTIVE") {
        return true;
      }
      return !this.affiliationLevel[0].inscriptionConfig.active
        ? true
        : this.affiliationLevel[0].paidInscription;
      // if (['PROSPECTO', 'EXPIRED'].includes(status)) {
      // } else {
      //   return true
      // }
    },

    ticketsToBuyIsZero() {
      return this.ticketsToBuy === 0;
    },

    isAffiliationLevelSelected() {
      return !!this.affiliationLevels.selected.id;
    },
    //  NEW
    async updateCoupon() {
      const { id: _, ...couponRest } = this.order.coupon;
      let updatedApplications = this.order.coupon?.applications || null;
      let couponLimitsCount = this.order.coupon?.limit || null;
      let updatedStatus = "ACTIVE";

      if (updatedApplications >= 0) {
        updatedApplications++;
      }
      // if (couponLimitsCount) {
      //   couponLimitsCount--;
      //   if (couponLimitsCount <= 0) {
      //     updatedStatus = "EXPIRED";
      //   }
      // }

      const data = {
        coupon: {
          ...couponRest,
          applications: updatedApplications,
          status: updatedStatus,
          limit: couponLimitsCount,
        },
      };

      const response = await this.axios.put(
        Endpoints.couponsId.replace(":id", this.order.coupon.id),
        data
      );

      if (response.status === 200) {
        const { coupon: responseCoupon } = response.data;

        // Aquí actualizamos la variable coupon si el ID coincide
        if (this.order.coupon.id === responseCoupon.id) {
          this.order.coupon = responseCoupon;
        }
      }
    },
    ////////////////////////////

    async addCoupon() {
      try {
        this.loading();
        const payload = {
          coupon: {
            id: this.id,

            affiliation: this.affiliationId,
            order: this.order.id,
            code: this.coupon,
          },
        };
        const response = await this.axios.post(
          Endpoints.affiliationCouponsValidate,
          payload
        );
        if (response.status === 200) {
          this.couponApplied = true;
          const { coupon } = response.data;
          this.order.coupon = coupon;
          this.setErrorToInitial("coupon");
          this.success.coupon = {
            success: true,
            message: `${coupon.percentOff}% de descuento, se aplicará al finalizar la compra.`,
          };
        }
      } catch (error) {
        const response = error.response;
        this.setSuccessToInitial("coupon");
        this.errors.coupon = {
          error: true,
          message:
            response && response.data && response.data.error
              ? response.data.error.message
              : "El cupón no es válido",
        };
      } finally {
        this.loaded();
      }
    },

    paymentSelection(toggle, paymentName, active) {
      if (paymentName == "CREDIT_CARD") {
        this.cardPaymentIntentId = "";
      }
      /*this.order.paymentInformation.paymentMethod = !active
        ? paymentName
        : null;*/

      Vue.set(
        this.order.paymentInformation,
        "paymentMethod",
        !active ? paymentName : null
      );
      toggle();
    },

    async handleRadioChange(level) {
      try {
        this.loading();
        // console.log(level);
        // console.log(this.affiliationLevel[0]._id);
        // const { _id } = this.affiliationLevel[0];
        // _id && (await this.removeItemFromOrder( _id ));
        await this.addItemToOrder(level.id);
        // this.affiliationLevel[0] = level;
      } catch (error) {
        console.log("error", error);
      } finally {
        this.loaded();
      }
    },

    async removeItemFromOrder(levelId) {
      const payload = {
        item: {
          order: this.order.id,
          affiliationType: levelId,
        },
      };
      console.log(payload);
      const response = await this.axios.delete(
        Endpoints.checkoutAffiliationItemManagement,
        { data: payload }
      );
    },

    async addItemToOrder(levelId) {
      const payload = {
        item: {
          order: this.order.id,
          affiliationType: levelId,
        },
      };
      if (["MEMBER", "MEMBERSHIP_USER"].includes(this.userData.role)) {
        payload.item["type"] = this.typeOfOrder;
        let memberID = this.membershipId ? this.membershipId : this.user._id;
        payload.item["member"] = memberID;
      }
      console.log(this.membershipId);
      let url = Endpoints.checkoutAffiliationItemManagement;
      if (this.membershipId) url = url + "/true"; ///valida al membership

      const response = await this.axios.post(url, payload);

      if (response.status === 200) {
        const { affiliationTypes, order } = response.data;
        this.order = { ...this.order, ...response.data.order };
        const activeLevels = affiliationTypes.filter(
          (level) => level.status === "ACTIVE"
        );
        // this.affiliationLevels.levels =
        //   this.formattedAffiliationLevels(activeLevels);
        this.order.itemId = order.items[0]._id;
        if (["MEMBER", "MEMBERSHIP_USER"].includes(this.userData.role)) {
          await this.setAffiliatiteInformationSave();
        }
      }
    },

    async setAffiliatiteInformationSave() {
      const { registrationFields } = this.affiliateInformation;
      const payload = {
        order: {
          id: this.order.id,
          items: [
            {
              _id: this.order.itemId,
              affiliateInformation: {
                ...registrationFields,
                name: this.order.paymentInformation.name,
                lastName: this.order.paymentInformation.lastName,
                email: this.order.paymentInformation.email,
              },
            },
          ],
        },
      };
      try {
        await this.axios.post(
          Endpoints.checkoutAffiliationSetAffiliateInfo,
          payload
        );
      } catch (error) {
        console.log(error);
      }
    },

    setErrorToInitial(errorKey) {
      if (this.errors[errorKey].error) {
        this.errors[errorKey] = {
          error: false,
          message: "",
        };
      }
    },
    setSuccessToInitial(successKey) {
      if (this.success[successKey].success) {
        this.success[successKey] = {
          success: true,
          message: "",
        };
      }
    },

    async createInscriptionOrder() {
      try {
        const response = await this.axios({
          url: `/affiliation-inscription/order-management/${this.affiliationType}/${this.membershipId}`,
          method: "post",
        });
        this.order = { ...this.order, ...response.data.order };
        this.inscription = { ...response.data.inscription };
        console.log(this.order);
      } catch (error) {
        console.log(error);
      }
    },

    async setOrderPaymentInformation() {
      this.order.paymentInformation.needsInvoice = this.acceptBill;
      this.order.paymentInformation = {
        ...this.order.paymentInformation,
      };
      if (this.installments.enable) {
        this.order["orderInstallments"] = this.installments.selected;
      }
      try {
        await this.axios({
          method: "post",
          url: Endpoints.checkoutSetPaymentInformation,
          json: true,
          // needsInvoice si es true no se genera a publico general
          data: {
            order: {
              id: this.order.id,
              paymentInformation: this.order.paymentInformation,
              orderInstallments: this.order.orderInstallments,
            },
          },
        });
      } catch (error) {
        console.log(error);
      } finally {
      }
    },
    async createOrder() {
      try {
        console.log(this.user.role);
        this.loading();
        const payload = {
          order: {
            affiliation: this.affiliationId,
          },
        };
        if (["MEMBER", "MEMBERSHIP_USER"].includes(this.userData.role)) {
          payload.order["type"] = this.typeOfOrder;
          let memberID = this.membershipId
            ? this.membershipId
            : this.userData._id;
          payload.order["member"] = memberID;
        }

        console.log(payload);
        const response = await this.axios.post(
          Endpoints.checkoutAffiliationCreateOrder,
          payload
        );

        if (response.status === 200) {
          const userInfo = {
            ...this.userData.name,
            ...this.userData.lastName,
            ...this.userData.email,
          };
          this.order = { ...this.order, ...response.data.order };
          this.order.paymentInformation = {
            ...this.order.paymentInformation,
            ...userInfo,
          };
          await this.handleRadioChange(this.affiliationLevel[0]);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        this.loaded();
      }
    },

    async finishBuying() {
      if (this.totalPurchaseWithDiscount.number === 0) {
        try {
          this.loading();
          if (this.order.coupon.id) {
            await this.updateCoupon();
          }
          console.log(this.order);
          await this.axios.post(Endpoints.checkoutAffiliationFreeOrder, {
            order: this.order,
          });
        } catch (error) {
          console.log("error", error);
        } finally {
          this.increaseStep();
          this.loaded();
        }
      } else {
        try {
          const { Cash, CreditCard, BankTransfer } = this.paymentMethodsKeys;
          const { paymentMethod } = this.order.paymentInformation;
          this.loading();

          const cases = {
            [Cash]: this.executeCashPayment,
            [CreditCard]: this.executeCardPayment,
            [BankTransfer]: this.executeBankTransferPayment,
          };

          cases[paymentMethod] && (await cases[paymentMethod]());
          if (this.order.coupon.id) {
            await this.updateCoupon();
          }
        } catch (error) {
          console.log("error", error);
        } finally {
          this.increaseStep();
          if (this.user.role == "MEMBER") this.reload();
          this.loaded();
        }
      }
    },
    async executeCardPayment() {
      try {
        let payment = {
          paymentIntentId: this.cardPaymentIntentId,
          selectedPlan: this.installments.selected,
        };
        if (this.stripeAccount != null) {
          payment.connectedAccount = this.stripeAccount;
        }
        await this.axios({
          method: "post",
          url: Endpoints.executeCardPayement,
          json: true,
          data: {
            payment: payment,
          },
        });

        await this.setOrderPaymentInformation();
      } catch (error) {
        this.errorMessage = "";
        let errorCode = error?.response?.data?.error?.code || null;

        if (error.declineCode && CardErrors[error.declineCode]) {
          this.errorMessage = CardErrors[error.declineCode];
          this.errorCode = error.declineCode;
        } else if (errorCode && CardErrors[errorCode]) {
          this.errorMessage = CardErrors[errorCode];
          this.errorCode = errorCode;
        } else {
          this.errorMessage =
            "El pago falló porque el banco emisor lo rechazó. Comunicate con el banco emisor para obtener más información.";
          this.errorCode = "";
        }

        this.isOpenErrorCardModal = true;
        this.currentStep--;
      }
    },
    async executePaymentAction() {
      this.loading();
      await this.setOrderPaymentInformation();
      this.increaseStep();
      this.loaded();
    },
    async executeCashPayment() {
      this.loading();

      let response = await this.axios({
        method: "post",
        url: Endpoints.cashPaymentIntent,
        json: true,
        data: {
          paymentMethod: { total: this.totalPurchaseWithDiscount.number },
          orderId: this.order.id,
        },
      }).catch((error) => {
        this.handleError(error.data.error.message);
      });

      this.clientSecret = response.data.paymentIntent.clientSecret;

      let stripe;
      if (this.stripeAccount) {
        stripe = Stripe(process.env.VUE_APP_STRIPE_API_KEY, {
          stripeAccount: this.stripeAccount,
        });
      } else {
        stripe = Stripe(process.env.VUE_APP_STRIPE_API_KEY);
      }

      const result = await stripe.confirmOxxoPayment(this.clientSecret, {
        payment_method: {
          billing_details: {
            name: `${this.user.name} ${this.user.firstLastname}`,
            email: this.user.email,
          },
        },
      });
      if (result.error) {
        console.log("MENSAJE DE ERROR");
        console.log(result.error);
        this.show({
          text: "¡No se ha podido realizar la transacción!",
          color: "error",
        });
      } else {
        console.log("stripe result", result);
        await this.axios({
          method: "PUT",
          url: `/orders/set-payment-reference/${this.order.id}`,
          data: {
            paymentReference:
              result.paymentIntent.next_action.oxxo_display_details
                .hosted_voucher_url,
          },
        });
        this.show({
          text: "Registro realizado correctamente",
          color: "primary",
        });
      }
      this.loaded();
    },
    async executeBankTransferPayment() {
      try {
        this.loading();

        //await this.setOrderPaymentInformation();

        let response = await this.axios({
          method: "post",
          url: Endpoints.bankTransferPaymentIntent,
          json: true,
          data: {
            paymentMethod: { total: this.totalPurchaseWithDiscount.number },
            customer: {
              name: `${this.order.paymentInformation.name} ${this.order.paymentInformation.lastName}`,
              email: this.order.paymentInformation.email,
            },
            orderId: this.order.id,
          },
        });

        this.clientSecret = response.data.paymentIntent.clientSecret;

        // const stripe = Stripe(process.env.VUE_APP_STRIPE_API_KEY, {
        //   stripeAccount: this.stripeAccount,
        //   apiVersion: "2020-08-27;customer_balance_payment_method_beta=v2",
        //   betas: ["customer_balance_beta_1"],
        // });

        let stripe;
        if (this.stripeAccount) {
          stripe = Stripe(process.env.VUE_APP_STRIPE_API_KEY, {
            stripeAccount: this.stripeAccount,
          });
        } else {
          stripe = Stripe(process.env.VUE_APP_STRIPE_API_KEY);
        }
        // let stripe;
        // if (this.stripeAccount) {
        // } else {
        //   stripe = Stripe(process.env.VUE_APP_STRIPE_API_KEY);
        // }
        let isAmpi = [
          "62b622c66a7441ae20159d7d",
          "6297b0fec7bf8b6f826313be",
        ].includes(this.organizationId);
        const result = await stripe.confirmCustomerBalancePayment(
          this.clientSecret,
          {
            payment_method: {
              billing_details: {
                name: `${this.order.paymentInformation.name} ${this.order.paymentInformation.lastName}`,
                email: this.order.paymentInformation.email,
              },
              customer_balance: {},
            },
            // payment_method_options: {
            //   customer_balance: {
            //     funding_type: "bank_transfer",
            //     bank_transfer: {
            //       type: "mx_bank_account",
            //     },
            //   },
            // },
          },
          {
            handleActions: !isAmpi,
          }
        );

        this.bankTransferInstructions =
          result.paymentIntent.next_action.display_bank_transfer_instructions;
        this.bankTransferDescription = result.paymentIntent.description
          ? result.paymentIntent.description
          : this.order.id;
        console.log(this.bankTransferInstructions);

        if (result.error) {
          this.show({
            text: "¡No se ha podido realizar la transacción!",
            color: "error",
          });
        } else {
          await this.axios({
            method: "PUT",
            url: `/orders/set-payment-reference/${this.order.id}`,
            data: {
              paymentReference:
                result.paymentIntent.next_action
                  .display_bank_transfer_instructions.hosted_instructions_url,
            },
          });
          this.show({
            text: "Registro realizado correctamente",
            color: "primary",
          });
          if (isAmpi) {
            this.voucherDialog = true;
            this.bankTransferInstructions =
              result.paymentIntent.next_action.display_bank_transfer_instructions;
            this.bankTransferDescription = result.paymentIntent.description
              ? result.paymentIntent.description
              : this.order.id;
          }
        }
      } catch (error) {
        console.log("error", error);
        this.show({
          text:
            error.response.data && error.response.data.error
              ? error.response.data.error
              : "¡No se ha podido realizar la transacción!",
          color: "error",
        });
      }
    },

    closeVoucherDialog() {
      this.voucherDialog = false;
    },
  },
  computed: {
    ...mapState("user", ["user"]),
    getBackToUserCheckout() {
      let route = {
        name: "Renew Membership",
        params: {
          setStep: 2,
          membershipId: this.userData.id,
          affiliationId: this.$route.params.organization,
        },
      };
      if (this.userData.role == "MEMBERSHIP_USER")
        route.name = "Member New Affiliate";
      return route;
    },
    activePaymentMethods() {
      // validates if payment methods are true
      let paymentMethodsActive = this.paymentMethods
        .map((method) =>
          method.name == "BANK_TRANSFER" ? method.active : method.active
        )
        .includes(true);
      this.paymentMethods = this.paymentMethods.filter(
        (method) => method.active == true
      );
      console.log(paymentMethodsActive);
      return paymentMethodsActive;
    },

    dateExpireOrder() {
      let expireIn = moment().set("hour", 72);
      return `${expireIn.format("h:mm")} hrs del dia ${expireIn.get(
        "date"
      )} de ${expireIn.format("MMMM")}`;
    },

    getBigSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "font-size:2.5em";
        case "sm":
          return "font-size:2.7em";
        case "md":
          return "font-size:2.7em";
        case "lg":
          return "font-size:3em";
        case "xl":
          return "font-size:3.2em";
      }
    },

    getSizePaymentsMethodsIcons() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "font-size:2.5em";
        case "sm":
          return "font-size:3em";
        case "md":
          return "font-size:3em";
        case "lg":
          return "font-size:2.3em";
        case "xl":
          return "font-size:2.5em";
      }
    },

    getSizeFinalIcon() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "font-size:4.5em";
        case "sm":
          return "font-size:4.5em";
        case "md":
          return "font-size:5em";
        case "lg":
          return "font-size:6.3em";
        case "xl":
          return "font-size:6.5em";
      }
    },

    totalPurchase() {
      const level = this.paidInscription
        ? this.affiliationLevel[0]
        : this.affiliationLevel[0].inscriptionConfig || {};
      return {
        string: level?.formattedPrice || formatPrice(0),
        number: level?.price || 0,
      };
    },

    totalPurchaseWithDiscount() {
      // console.log(this.order);

      let total = this.order.total;
      if (this.userData.membershipPrice && this.paidInscription) {
        total = this.userData.membershipPrice;
      }
      let percent = this.order.coupon.percentOff / 100;
      const discount = this.discount.number;
      const totalWithDiscount = total - total * percent;

      return {
        string: formatPrice(totalWithDiscount),
        number: totalWithDiscount,
      };
    },

    discount() {
      const total = this.totalPurchase.number;
      const discount = total * (this.order.coupon.percentOff / 100);
      return {
        string: formatPrice(discount),
        number: discount,
      };
    },

    disableContinueBuyButton() {
      const { name, lastName, email, paymentMethod } =
        this.order.paymentInformation;
      let commonRules;

      if (
        ["62b622c66a7441ae20159d7d", "6297b0fec7bf8b6f826313be"].includes(
          this.headOffice
        )
      ) {
        commonRules =
          !this.termsOfUSe.terms ||
          !paymentMethod ||
          !this.billingPolitics.terms;
      } else {
        commonRules = !this.termsOfUSe.terms || !paymentMethod;
      }

      const creditCardRules =
        paymentMethod === this.paymentMethodsKeys.CreditCard &&
        !this.cardPaymentIntentId;

      return (
        commonRules ||
        creditCardRules ||
        !(this.acceptBill == true || this.declineBill == true)
      );
    },
  },
  async created() {
    await this.getAffiliationInfo(); // get Affiliation Information
    if (this.paidInscription) {
      console.log("entra a order"); // if user has paid inscription
      await this.createOrder(); // create affiliation order
    } else {
      await this.createInscriptionOrder(); // create inscription order
    }
    // await this.getPaymentMethods();
  },
};
</script>
<style scoped></style>
